import React from 'react';
import Button from 'react-bootstrap/Button';

class ProfileDeleteButton extends React.Component {
  render() {
    return (
      <div>
        <Button onClick={this.props.click} size="sm" variant="outline-dangers">
          <i className="material-icons">delete</i>
        </Button>
      </div>
    );
  }
}
export default ProfileDeleteButton;
