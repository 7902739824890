import React from "react";
import { Link } from "react-router-dom";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import EditDerivationModal from "./EditDerivationModal";
import NoDerivationsInstalled from "./NoDerivationsInstalled";
import Tooltip from "react-bootstrap/Tooltip";
import {
  CVEBadgeByCPE,
  CVEBadgeByNixStorePath,
  CVEBadgeByReferences,
} from "../common/CVEBadge";
import SbomHref from "../common/SbomHref";
import GlobalStateContext from "../GlobalState";

import {
  getNixStorePathInfo,
  getPackageName,
  getPackageNameWithOutType,
  getSimplePackageName,
  getVersion,
  getVersionLink,
  removePackage,
  setDerivationPriority,
  delNixPath,
  addPackageHold,
  removePackageHold,
} from "../utils";

const spinner = (
  <Spinner animation="border" role="status">
    <span className="sr-only">Loading...</span>
  </Spinner>
);

class CurrentGenerationListGroup extends React.Component {
  defaultPriority = 5;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      selected: {},
      showOutPaths: false,
    };
  }

  static contextType = GlobalStateContext;

  getVariant = (priority) => {
    if (priority < this.defaultPriority) {
      return "success";
    }
    return "secondary";
  };

  getSbomHref = (path) => {
    return SbomHref(this.context.config.hydraURL, path);
  };

  filterDerivations = () => {
    var seen = {};
    return this.props.derivations.filter((drv) => {
      if (!drv.drvID || drv.drvID === 0) {
        return true;
      }
      return seen.hasOwnProperty(drv.drvID) ? false : (seen[drv.drvID] = true);
    });
  };

  removePackage = (chn, job, pkg) => {
    removePackage(this.props.owner, this.props.profile, {
      packages: [{ channel: { name: chn, jobset: job }, packageName: pkg }],
    }).then((res) => {
      if (res.ok) {
        this.setState({ show: false, derivation: {} });
        this.props.update();
      }
    });
  };

  delNixPath = (path) => {
    delNixPath(this.props.owner, this.props.profile, {
      packages: [{ nixStorePath: path }],
    }).then((res) => {
      if (res.ok) {
        this.setState({ show: false, derivation: {} });
        this.props.update();
      }
    });
  };

  setPackagePriority = (pvrID, pkgVarID, priority) => {
    setDerivationPriority(pvrID, pkgVarID, priority).then((res) => {
      if (res.ok) {
        this.props.update();
        this.setState({ show: false });
      }
    });
  };

  addPackageHold = (path) => {
    addPackageHold(this.props.owner, this.props.profile, path).then((res) => {
      if (res.ok) {
        this.props.update();
      }
    });
  };

  removePackageHold = (path) => {
    removePackageHold(this.props.owner, this.props.profile, path).then(
      (res) => {
        if (res.ok) {
          this.props.update();
        }
      }
    );
  };

  genPopover = (hold) => (
    <Tooltip>{hold ? "Resume " : "Pause "} upgrades for this package</Tooltip>
  );

  render() {
    if (!this.props.derivations) {
      return spinner;
    }
    if (this.props.derivations.length === 0) {
      return <NoDerivationsInstalled />;
    }

    const { derivations, owner, profile, pvrID } = this.props;
    const { selected } = this.state;

    if (!this.props.showOutPaths) {
      if (derivations.length === 0) {
        return <Spinner animation="border" size="sm" variant="secondary" />;
      }

      const derivationsGrouped = derivations.reduce((result, drv) => {
        const key = `${getSimplePackageName(drv)}-${getVersion(drv)}`;
        result[key] = result[key] || [];
        result[key].push(drv);

        return result;
      }, {});

      return (
        <React.Fragment>
          <Accordion>
            {Object.values(derivationsGrouped).map((drvs, index) => (
              <DerivationsReferences
                drvs={drvs}
                getSbomHref={this.getSbomHref}
                index={index}
                parentThis={this}
              />
            ))}
          </Accordion>

          <EditDerivationModal
            show={this.state.show}
            close={() => this.setState({ show: false })}
            owner={owner}
            derivation={selected}
            outputs={derivations.filter((drv) => drv.drvID == selected.drvID)}
            name={getPackageName(selected)}
            profile={profile}
            cancel={() => this.setState({ show: false })}
            setPriority={(priority) =>
              this.setPackagePriority(pvrID, selected.pkgVarID, priority)
            }
            removeDerivation={() =>
              this.removePackage(
                selected.chnName,
                selected.jobset,
                selected.pkgName
              )
            }
            removeNixPath={(path) => () =>
              this.delNixPath("/nix/store/" + path)}
          />
        </React.Fragment>
      );
    }
    return (
      <ListGroup variant="flush">
        {derivations.map((drv) => (
          <ListGroup.Item className="py-1">
            <Row>
              <Col>
                <div>{this.getSbomHref("/nix/store/" + drv.nixStoreKey)}</div>
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  }
}

const DerivationsReferences = ({ drvs, getSbomHref, index, parentThis }) => {
  const [requisites, setRequisites] = React.useState(null);

  React.useEffect(() => {
    async function effect() {
      setRequisites(null);
      const result = [];
      for (const drv of drvs) {
        const response = await getNixStorePathInfo(
          `/nix/store/${drv.nixStoreKey}`
        );
        const responseData = await response.json();
        result.push(responseData);
      }
      setRequisites(result);
    }

    effect();
  }, [drvs]);

  const name = getPackageName(drvs[0]);
  const version = getVersion(drvs[0]);

  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle as={Container} eventKey={index.toString()}>
          <Row>
            <Col>{name}</Col>
            <Col>{getVersionLink(drvs[0])}</Col>
            <Col sm={1}>
              <CVEBadgeByReferences
                name={`${name} ${version}`}
                references={
                  requisites === null
                    ? null
                    : requisites
                        .map((requisite) => requisite.info.references)
                        .flat()
                }
              />
            </Col>
            <Col sm={1}>
              <Badge variant={parentThis.getVariant(drvs[0].priority)}>
                <big>
                  {drvs[0].priority
                    ? drvs[0].priority
                    : parentThis.defaultPriority}
                </big>
              </Badge>
            </Col>
            <Col xs={1}>
              <i
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  parentThis.props.admin
                    ? parentThis.setState({ selected: drvs[0], show: true })
                    : parentThis.props.authenticate();
                }}
                className="material-icons click"
              >
                edit
              </i>
            </Col>
            <Col xs={1}>
              <OverlayTrigger
                placement="top"
                overlay={parentThis.genPopover(
                  parentThis.props.holds.includes(drvs[0].dvoID)
                )}
              >
                <i
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    parentThis.props.admin
                      ? parentThis.props.holds.includes(drvs[0].dvoID)
                        ? parentThis.removePackageHold(drvs[0].nixStoreKey)
                        : parentThis.addPackageHold(drvs[0].nixStoreKey)
                      : parentThis.props.authenticate();
                  }}
                  className="material-icons click"
                >
                  {parentThis.props.holds.includes(drvs[0].dvoID)
                    ? "play_arrow"
                    : "pause"}
                </i>
              </OverlayTrigger>
            </Col>
          </Row>
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={index.toString()}>
        <Card.Body>
          {requisites === null ? (
            <Spinner animation="border" size="sm" variant="secondary" />
          ) : (
            drvs.map((drv, index) => (
              <React.Fragment>
                <p>
                  Runtime dependencies for {getPackageNameWithOutType(drv)}:
                </p>
                <ul>
                  {(requisites[index].info.references || []).map((requisite) => (
                    <li style={{ paddingLeft: "2rem" }}>
                      <Row>
                        <Col sm={11}>{getSbomHref(requisite)}</Col>
                        <Col sm={1}>
                          <CVEBadgeByNixStorePath nixStorePath={requisite} />
                        </Col>
                      </Row>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ))
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default CurrentGenerationListGroup;
