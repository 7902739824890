import React from "react";
import Container from "react-bootstrap/Container";
import logo from "../assets/flox_blue_x50.png";
import { Redirect } from "react-router-dom";
import { GithubLoginButton } from "react-social-login-buttons";
import GlobalStateContext from "../GlobalState";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  static contextType = GlobalStateContext;

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  render() {
    const { redirect } = this.state;
    const { config } = this.context;

    if (config.variant === "adfs") {
      window.location.href = config.authURL;
    }

    return (
      <Container fluid>
        <br />
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <div className="d-flex flex-column align-items-center">
            <img src={logo} alt="logo" />
            <br />
            <div className="login-header">Sign in to FloxPM</div>
            <br />
            <GithubLoginButton
              size="40px"
              iconSize="22px"
              onClick={() => (window.location.href = config.authURL)}
            />
            <hr />
            <small>
              Haven't signed up yet?{" "}
              <a className="link-text" href="https://floxdev.com">
                {" "}
                Register.
              </a>
            </small>
          </div>
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <small>
            By entering this website, you agree to{" "}
            <a
              href="https://flox-legal.s3.eu-west-2.amazonaws.com/flox-license.pdf"
              target="_blank"
            >
              these terms and conditions
            </a>
            .
          </small>
        </div>
        {redirect && <Redirect to="/profiles" />}
      </Container>
    );
  }
}

export default Login;
