import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import DeleteProfileFailure from "./DeleteProfileFailure";
import { deleteProfile } from "./../utils";

class DeleteProfileConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "",
      error: "",
      profile: "",
      inProgress: false,
    };
  }

  submitForm = (e) => {
    e.preventDefault();
    if (this.state.profile === this.getProfile()) {
      this.delete();
    }
  };

  getProfile = () => {
    return this.props.owner + "/" + this.props.name;
  };

  handleChange = (event) => {
    this.setState({ profile: event.target.value });
  };

  delete = () => {
    this.setState({ inProgress: true });
    deleteProfile(this.props.owner, this.props.name)
      .then((res) => {
        if (res.ok) {
          this.props.success();
          this.setState({ profile: "", inProgress: false });
        } else {
          this.setState({
            mode: "failure",
            error: res.error,
            inProgress: false,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  render() {
    const { inProgress } = this.state;
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.close}>
          <Modal.Header className="modal-background" closeButton>
            <Modal.Title>
              {this.props.owner}/{this.props.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              Are you sure you want to delete this profile? Please type in the
              fully qualified name (i.e owner/profile) to confirm.
            </div>
            <DeleteProfileFailure show={this.state.mode === "failure"} />
            <br />
            <Form onSubmit={this.submitForm}>
              <InputGroup>
                <Form.Control
                  type="text"
                  value={this.state.profile}
                  onChange={this.handleChange}
                />
                <InputGroup.Append>
                  {!inProgress && (
                    <Button
                      disabled={this.state.profile !== this.getProfile()}
                      variant="danger"
                      onClick={this.delete}
                      size="sm"
                    >
                      Confirm
                    </Button>
                  )}
                  {inProgress && (
                    <Button variant="danger" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Loading...</span>
                    </Button>
                  )}
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default DeleteProfileConfirm;
