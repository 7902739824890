import React from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

class CreateProfileAlertFailure extends React.Component {
  render() {
    return (
      <Alert variant="danger" show={this.props.show}>
        <Alert.Heading>Failed to create profile</Alert.Heading>
        <hr />
        <b>{this.props.error}</b>
        <div className="d-flex justify-content-end">
          <Button size="sm" onClick={this.props.close} variant="outline-danger">
            Close
          </Button>
        </div>
      </Alert>
    );
  }
}

export default CreateProfileAlertFailure;
