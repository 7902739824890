import React from "react";
import Moment from "react-moment";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Footer from "../common/Footer";
import AccessTokenModal from "../common/AccessTokenModal";
import DeleteAccountConfirm from "./DeleteAccountConfirm";
import GlobalStateContext from "../GlobalState";
import {
  getAccessTokens,
  reqAccessToken,
  revokeAccessToken,
  selectRange,
} from "../utils";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      selected: "developer",
      tokens: [],
      delete: false,
      token: "",
      showTokens: [],
    };
  }

  static contextType = GlobalStateContext;

  componentDidMount = () => {
    this.listAccessTokens();
  };

  listAccessTokens = () => {
    getAccessTokens()
      .then((res) => res.ok && res.json())
      .then(
        (res) =>
          res.tokens &&
          this.setState({ tokens: res.tokens.filter((tok) => !tok.cookie) })
      )
      .catch((err) => console.log(err));
  };

  reqAccessToken = () => {
    reqAccessToken()
      .then((res) => res.ok && res.json())
      .then((res) => {
        this.setState({ token: res.UUID });
        this.listAccessTokens();
      });
  };

  revokeAccessToken = (token) => () => {
    revokeAccessToken(token).then((res) => res.ok && this.listAccessTokens());
  };

  handleSelect = (e) => {
    this.setState({ selected: e.target.id });
  };

  removeToken = (token) => () => {
    const index = this.state.showTokens.indexOf(token);
    if (index > -1) {
      this.setState({
        showTokens: this.state.showTokens.filter((tok) => tok != token),
      });
    }
  };

  addToken = (token) => () => {
    this.setState({ showTokens: [...this.state.showTokens, token] });
  };

  render() {
    const { selected, tokens, token, showTokens } = this.state;
    const { user, info } = this.context;

    return (
      <div>
        {this.props.navBar()}
        <br />
        <Container>
          <br />
          <Tab.Container id="user-settings">
            <Row>
              <Col md={4}>
                <img
                  alt="avatar"
                  className="large-avatar"
                  src={user.AvatarURL}
                />
                <hr />
                <ul>
                  <li
                    className={selected === "personal" && "selected"}
                    id="personal"
                  >
                    <div className="d-flex">
                      <i className="material-icons">account_box</i>
                      <div className="large-divider" />
                      <div
                        className="clickable"
                        id="personal"
                        onClick={this.handleSelect}
                      >
                        Personal
                      </div>
                    </div>
                  </li>
                  <li
                    id="developer"
                    className={selected === "developer" && "selected"}
                    onClick={this.handleSelect}
                  >
                    <div className="d-flex">
                      <i className="material-icons">devices</i>
                      <div className="large-divider" />
                      <div
                        className="clickable"
                        id="developer"
                        onClick={this.handleSelect}
                      >
                        Developer Settings
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
              <Col md={8}>
                <Tab.Content>
                  <Tab.Pane active={this.state.selected === "personal"}>
                    <b>Account Settings</b>
                    <hr />
                    <br />
                    <Row className="align-items-center">
                      <Col md={7}>
                        <b className="danger">Delete account</b>
                        <br />
                        <small>
                          Permanently delete your account and all data.
                        </small>
                      </Col>
                      <Col md={5}>
                        <div
                          size="sm"
                          variant="outline-info"
                          className="link-text medium"
                          onClick={() => this.setState({ delete: true })}
                        >
                          Delete account
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane active={this.state.selected === "developer"}>
                    <b>Developer Settings</b>
                    <hr />
                    <br />
                    <Row className="align-items-center">
                      <Col md={7}>
                        <b>Request access token</b>
                        <br />
                        <small>
                          Request a developer token for logging in using the
                          CLI.
                        </small>
                      </Col>
                      <Col md={5}>
                        <div
                          size="sm"
                          variant="outline-info"
                          className="link-text medium"
                          onClick={this.reqAccessToken}
                        >
                          Request access token
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md={3}>
                        <b>Access tokens</b>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        {tokens.length === 0 && <div>No tokens</div>}
                        {tokens.length !== 0 &&
                          tokens.map((tok) => {
                            var show = showTokens.includes(tok.UUID);
                            return (
                              <div>
                                <Row className="align-items-center">
                                  <Col md={8}>
                                    <div className="d-flex align-items-center">
                                      <i
                                        className="material-icons click"
                                        onClick={
                                          show
                                            ? this.removeToken(tok.UUID)
                                            : this.addToken(tok.UUID)
                                        }
                                      >
                                        {show ? "visibility_off" : "visibility"}
                                      </i>
                                      <div className="large-divider" />
                                      <input
                                        className="token-input"
                                        type={show ? "text" : "password"}
                                        disabled
                                        value={tok.UUID}
                                        ref={this.command}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={3}>
                                    <div className="small">
                                      <Moment fromNow>{tok.lastSeen}</Moment>
                                    </div>
                                  </Col>
                                  <Col md={1}>
                                    <div
                                      className="revoke medium"
                                      onClick={this.revokeAccessToken(tok.UUID)}
                                    >
                                      Revoke
                                    </div>
                                  </Col>
                                </Row>
                                <hr />
                              </div>
                            );
                          })}
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <Footer user={user.username} info={info} />
        </Container>
        <AccessTokenModal
          show={token !== ""}
          token={token}
          close={() => this.setState({ token: "" })}
        />
        <DeleteAccountConfirm
          user={user.username}
          show={this.state.delete}
          close={() => this.setState({ delete: false })}
        />
      </div>
    );
  }
}

export default Settings;
