import React from "react";

import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import {
  CVEBadgeByCPE,
  CVEBadgeByNixStorePath,
  CVEBadgeByReferences,
} from '../common/CVEBadge';

import {
  getNixStorePathInfo,
  getPackageName,
  getPackageNameWithOutType,
  getSimplePackageName,
  getVersion,
  getVersionLink,
} from "../utils";

const GenerationDerivationsTable = ({ derivations, getSbomHref }) => {
  if (derivations.length === 0) {
    return <Spinner animation="border" size="sm" variant="secondary" />;
  }

  const derivationsGrouped = derivations.reduce((result, drv) => {
    const key = `${getSimplePackageName(drv)}-${getVersion(drv)}`;
    result[key] = result[key] || [];
    result[key].push(drv);

    return result;
  }, {});

  return (
    <Accordion>
      {Object.values(derivationsGrouped).map((drvs, index) => (
        <DerivationsReferences
          drvs={drvs}
          getSbomHref={getSbomHref}
          index={index}
        />
      ))}
    </Accordion>
  );
}

const DerivationsReferences = ({ drvs, getSbomHref, index }) => {
  const [requisites, setRequisites] = React.useState(null);

  React.useEffect(() => {
    async function effect() {
      setRequisites(null);
      const result = [];
      for (const drv of drvs) {
        const response = await getNixStorePathInfo(`/nix/store/${drv.nixStoreKey}`);
        const responseData = await response.json();
        result.push(responseData);
      }
      setRequisites(result);
    }

    effect();
  }, [drvs]);

  const name = getPackageName(drvs[0]);
  const version = getVersion(drvs[0]);

  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle as={Container} eventKey={index.toString()}>
          <Row>
            <Col>{name}</Col>
            <Col>{getVersionLink(drvs[0])}</Col>
            <Col>
              <CVEBadgeByReferences
                name={`${name} ${version}`}
                references={
                  requisites === null
                    ? null
                    : requisites
                      .map((requisite) => requisite.info.references)
                      .flat()
                }
              />
            </Col>
          </Row>
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={index.toString()}>
        <Card.Body>
          {requisites === null ? (
            <Spinner animation="border" size="sm" variant="secondary" />
          ) : (
            drvs.map((drv, index) => (
              <React.Fragment>
                <p>Runtime dependencies for {getPackageNameWithOutType(drv)}:</p>
                <ul>
                  {(requisites[index].info.references || []).map((requisite) => (
                    <li style={{ paddingLeft: "2rem" }}>
                      <Row>
                        <Col sm={11}>{getSbomHref(requisite)}</Col>
                        <Col sm={1}>
                          <CVEBadgeByNixStorePath nixStorePath={requisite} />
                        </Col>
                      </Row>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ))
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

export default GenerationDerivationsTable;
