import React from "react";
import Table from "react-bootstrap/Table";
import SbomHref from "../common/SbomHref";
import GlobalStateContext from "../GlobalState";
import { getPackageName } from "../utils";

class DerivationOutputsTable extends React.Component {
  nixStorePath = "/nix/store/";

  static contextType = GlobalStateContext;

  getSbomHref = (path) => {
    return SbomHref(this.context.config.hydraURL, path);
  };

  render() {
    if (!this.props.derivations) {
      return null;
    }

    return (
      <Table size="sm" striped bordered>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Path</th>
          </tr>
        </thead>
        <tbody>
          {this.props.derivations.map((drv) => (
            <tr>
              <td>{getPackageName(drv)}</td>
              <td>{drv.outType ? drv.outType : "out"}</td>
              <td>{this.getSbomHref(this.nixStorePath + drv.nixStoreKey)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export default DerivationOutputsTable;
