import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";

class NoProfilesModal extends React.Component {
  render = () => {
    return (
      <Modal
        show={this.props.show && this.state.show}
        onHide={() => {
          this.resetState();
          this.props.close();
        }}
      >
        <Modal.Header className="modal-background">
          <Modal.Title>
            <div>You have no profiles!</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="d-flex justify-content-center">
              You'll need to create a profile to install packages to.
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
}

export default NoProfilesModal;
