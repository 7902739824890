import React from "react";
import Moment from "react-moment";

import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import ProfileGenerationsButton from "./ProfileGenerationsButton";
import ProfileDeleteButton from "./ProfileDeleteButton";
import ProfileSettingsButton from "./ProfileSettingsButton";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

class ProfilesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: {},
      show: false,
    };
  }

  isDefaultProfile = (prof) => {
    if (prof.proOwner == this.props.user) {
      if (prof.proName == "default") {
        return true;
      }
    }
    return false;
  };

  genTooltip = (time) => {
    return (
      <Popover>
        <Popover.Content>
          <Moment format="Do MMM YYYY HH:mm">{time}</Moment>
        </Popover.Content>
      </Popover>
    );
  };

  subscribe = (subscribed, owner, name) => {
    if (subscribed) {
      this.props.unsubscribe(owner, name);
    } else {
      this.props.subscribe(owner, name);
    }
  };

  getFollowButton = (prof) => {
    if (this.props.user === prof.proOwner) {
      return null;
    }
    var subscribed = this.props.subscriptions.includes(prof.proID);
    return (
      <Button
        onClick={() => this.subscribe(subscribed, prof.proOwner, prof.proName)}
        variant={subscribed ? "outline-danger" : "outline-info"}
        size="sm"
      >
        {subscribed ? "Unfollow" : "Follow"}
      </Button>
    );
  };

  nextUpgrade = (prof) => {
    if (prof.nextUpgrade) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={this.genTooltip(prof.nextUpgrade)}
        >
          <Moment fromNow>{prof.nextUpgrade}</Moment>
        </OverlayTrigger>
      );
    }
    return <div>{prof.uppName}</div>;
  };

  getUpgradeProfile = (prof) => {
    return (
      <Link to={"/profiles/" + prof.proOwner + "/" + prof.proName + "/status"}>
        {prof.proOwner}/{prof.proName}
      </Link>
    );
  };

  render() {
    const { variant } = this.props;
    if (!this.props.profiles) {
      return null;
    }
    return (
      <div style={{
        height: "50vh",
        overflow: "auto",
      }}>
      <Table size="sm" striped>
        <thead>
          <tr>
            {variant === "flox" && <th>System</th>}
            <th>Owner</th>
            <th>Name</th>
            {variant === "desco" && <th>Generation</th>}
            <th>Action</th>
            <th>Upgrade Policy</th>
            {variant === "desco" && <th>Upgrade From</th>}
            <th>Next Upgrade</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.profiles.map((prof) => (
            <tr
              className={this.isDefaultProfile(prof) ? "table-success" : ""}
              key={prof.proOwner + prof.proName}
            >
              {variant === "flox" && <td>{prof.systemName}</td>}
              <td>
                <Link to={"/users/" + prof.proOwner}>{prof.proOwner}</Link>
              </td>
              <td>
                <Link
                  to={
                    "/profiles/" +
                    prof.proOwner +
                    "/" +
                    prof.proName +
                    "/status"
                  }
                >
                  {prof.proName}
                </Link>
              </td>
              {variant === "desco" && <td>{prof.gen}</td>}
              <td>
                <ButtonGroup>
                  <ProfileDeleteButton click={this.props.selected(prof)} />
                  <ProfileGenerationsButton
                    name={prof.proName}
                    owner={prof.proOwner}
                    disabled={prof.pviID}
                  />
                  <ProfileSettingsButton
                    name={prof.proName}
                    owner={prof.proOwner}
                  />
                </ButtonGroup>
              </td>
              <td>{prof.uppName}</td>
              {variant === "desco" && (
                <td>
                  {prof.upgradeFrom && this.getUpgradeProfile(prof.upgradeFrom)}
                </td>
              )}
              <td>{this.nextUpgrade(prof)}</td>
              <td>{this.getFollowButton(prof)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </div>
    );
  }
}

export default ProfilesTable;
