import React from 'react';

function SbomHref(url, path) {
  var parts = url.split('/');
  parts.splice(3, 2, 'sbom/explore.html?path=' + path);
  var hrefUrl = parts.join('/');
  return (<a className="link-text" href={hrefUrl}>{path}</a>);
}

export default SbomHref;
