import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const defaultUpgradePolicy = "weekly";
const defaultSystem = "x86_64-linux";
const nixPath = "/nix/profiles/";

class CreateProfileForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "default",
      user: this.props.user,
      policy: defaultUpgradePolicy,
      system: defaultSystem
    };
  }

  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  submit = () => {
    this.props.onSubmitForm(
      this.state.name,
      this.state.policy,
      this.state.system
    );
  };

  render() {
    return (
      <div>
        <Form>
          <Form.Group controlId="name">
            <Form.Row>
              <Col>
                <Form.Label>
                  <strong>Profile path</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={nixPath + this.props.user + "/" + this.state.name}
                  onChange={this.handleChange}
                  disabled
                />
                <Form.Text className="text-muted">
                  The path to the profile that will be created.
                </Form.Text>
              </Col>
              <Col>
                <Form.Label>
                  <strong>Profile name</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  value={this.state.name}
                  onChange={this.handleChange}
                  autocomplete="off"
                />
                <Form.Text className="text-muted">
                  Your first profile should be called "default".
                </Form.Text>
              </Col>
            </Form.Row>
          </Form.Group>

          <hr />

          <Form.Row>
            <Col>
              <Form.Label>
                <strong>Owner</strong>
              </Form.Label>
              <Form.Control
                id="owner"
                type="text"
                defaultValue={this.props.user}
                onChange={this.handleChange}
                disabled
              />
            </Col>
            <Col>
              <Form.Label>
                <strong>Group</strong>
              </Form.Label>
              <Form.Control
                id="grp"
                type="text"
                defaultValue={this.props.user}
                onChange={this.handleChange}
                disabled
              />
            </Col>
          </Form.Row>

          <hr />

          <Form.Group controlId="upgradePolicy">
            <Form.Row>
              <Col>
                <Form.Label>
                  <strong>Upgrade policy</strong>
                </Form.Label>
                <Form.Control
                  id="policy"
                  as="select"
                  value={this.state.policy}
                  onChange={this.handleChange}
                >
                  {this.props.policies.map(policy => (
                    <option value={policy.name}>{policy.name}</option>
                  ))}
                </Form.Control>
                <Form.Text className="text-muted">
                  Upgrade policies determine how frequently packages installed
                  to your profile will be upgraded.
                </Form.Text>
              </Col>
              <Col>
                <Form.Label>
                  <strong>System</strong>
                </Form.Label>
                <Form.Control
                  id="system"
                  as="select"
                  value={this.state.system}
                  onChange={this.handleChange}
                >
                  {this.props.systems.map(sys => (
                    <option value={sys.name}>{sys.name}</option>
                  ))}
                </Form.Control>
                <Form.Text className="text-muted">
                  Select system this profile will run on.
                </Form.Text>
              </Col>
            </Form.Row>
          </Form.Group>

          <hr />
          <br />
          <Form.Row>
            <Col>
              <Button
                disabled={this.state.name === ""}
                variant="success"
                size="sm"
                onClick={this.submit}
              >
                Create Profile
              </Button>
              <div className="divider" />
              <Link to="/profiles">
                <Button variant="success" size="sm">
                  Back
                </Button>
              </Link>
            </Col>
          </Form.Row>
        </Form>
        <br />
      </div>
    );
  }
}

export default CreateProfileForm;
