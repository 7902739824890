import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "./assets/flox_blue_x50.png";
import { NavLink } from "react-router-dom";
import GlobalStateContext from "./GlobalState";

class NavigationBar extends React.Component {
  static contextType = GlobalStateContext;

  render() {
    const { user } = this.props;
    const { docsURL } = this.context.config;

    return (
      <Navbar bg="light" variant="light">
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="logo" />
          </Navbar.Brand>
          <div className="large-divider" />
          <Nav className="mr-auto align-items-center">
            <NavLink
              to="/profiles"
              className="link"
              activeClassName="link-active"
            >
              Profiles
            </NavLink>
            <div className="large-divider" />
            <NavLink
              to="/packages"
              className="link"
              activeClassName="link-active"
            >
              Packages
            </NavLink>
            <div className="large-divider" />
            <li>
              <a target="_blank" href={docsURL} className="link">
                Documentation
              </a>
            </li>
          </Nav>
          <div className="d-flex justify-content-end align-items-center">
            <div className="bold">{user}</div>
            <div className="divider" />
            <img
              alt="avatar"
              className="avatar"
              src={
                "https://employeeprofile/api/employees/" +
                user +
                "/photo?useDefaultPhoto=true"
              }
            />
          </div>
        </Container>
      </Navbar>
    );
  }
}

export default NavigationBar;
