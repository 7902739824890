import React from "react";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Dimensions from "react-dimensions";
import {
  HorizontalGridLines,
  LineSeries,
  RadialChart,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";

const SeverityByCategoryUnwrapped = ({
  containerHeight,
  containerWidth,
  data,
}) => (
  <RadialChart
    data={data}
    height={containerHeight}
    // innerRadius={0}
    // radius={containerWidth}
    width={containerWidth}
  />
);

const SeverityScoreUnwrapped = ({ containerHeight, containerWidth, data }) => (
  <RadialChart
    data={data}
    height={containerHeight}
    innerRadius={Math.round(containerWidth * 0.35)}
    labelsRadiusMultiplier={0.01}
    radius={Math.round(containerWidth * 0.4)}
    showLabels={true}
    width={containerWidth}
  />
);

const ScoreOverTimeUnwrapped = ({ containerHeight, containerWidth, data }) => (
  <XYPlot height={containerHeight} width={containerWidth}>
    <HorizontalGridLines />
    <LineSeries data={data} />
    <XAxis />
    <YAxis />
  </XYPlot>
);

const SeverityByCategory = Dimensions({
  containerStyle: { width: "100%", height: "10rem", padding: 0, border: 0 },
  elementResize: true,
})(SeverityByCategoryUnwrapped);

const SeverityScore = Dimensions({
  containerStyle: { width: "100%", height: "10rem", padding: 0, border: 0 },
  elementResize: true,
})(SeverityScoreUnwrapped);

const ScoreOverTime = Dimensions({
  containerStyle: { width: "100%", height: "10rem", padding: 0, border: 0 },
  elementResize: true,
})(ScoreOverTimeUnwrapped);

const SecurityDashboard = ({}) => {
  return (
    <Container>
      <Row>
        <Col sm={3}>
          <SeverityByCategory
            data={[
              { angle: 1, label: "Low" },
              { angle: 1, label: "Medium" },
              { angle: 1, label: "High" },
            ]}
          />
        </Col>
        <Col sm={3}>
          <SeverityScore
            data={[{ angle: 60, label: "Severity Score" }, { angle: 40 }]}
          />
        </Col>
        <Col sm={6}>
          <ScoreOverTime
            data={[
              { x: 1, y: 10 },
              { x: 2, y: 5 },
              { x: 3, y: 15 },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            <b>Top Action Items</b>
          </span>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={11}>
          <Table size="sm">
            <thead>
              <tr>
                <th>Profile</th>
                <th>Derivation</th>
                <th>Severity</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export { SecurityDashboard };
