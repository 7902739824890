import React from "react";
import Moment from "react-moment";

import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { selectRange } from "../utils";

class ProfilePackagesCard extends React.Component {
  path = React.createRef();
  profileURI = React.createRef();

  formatTime = time => {
    return <Moment format="Do MMM YYYY HH:mm">{time}</Moment>;
  };

  copyPath = () => {
    const el = this.path.current;
    selectRange(el);
  };

  copyProfileURI = () => {
    const el = this.profileURI.current;
    selectRange(el);
  };

  render() {
    return (
      <Card bg="light" border="secondary">
        <Card.Body>
          <div className="d-flex justify-content-between profile-heading">
            <div>{this.props.name}</div>
            <OverlayTrigger
              trigger="hover"
              placement="top"
              overlay={<Tooltip>Derivation outputs installed</Tooltip>}
            >
              <div>{this.props.packages}</div>
            </OverlayTrigger>
          </div>
          <p>{this.props.variant.description}</p>
          <hr />
          <a className="d-flex justify-content-between card-text">
            <div>Current Gen</div>
            <div>{this.props.currGen}</div>
          </a>
          <a className="d-flex justify-content-between card-text">
            <div>Generations</div>
            <div>{this.props.generations}</div>
          </a>
          <hr />
          <a className="d-flex justify-content-between card-text">
            <div>Created</div>
            <div>{this.formatTime(this.props.variant.cTime)}</div>
          </a>
          <a className="d-flex justify-content-between card-text">
            <div>Last Modified</div>
            <div>{this.formatTime(this.props.variant.mTime)}</div>
          </a>
          <hr />

          <a className="d-flex align-items-left card-text">
            <div>Path</div>
          </a>
          <a className="d-flex align-items-right card-text">
            <Badge variant="warning" onClick={this.copyPath}>
              <div ref={this.path} className="path">
                {"/nix/profiles/" +
                  this.props.variant.proOwner +
                  "/" +
                  this.props.variant.proName}
              </div>
            </Badge>
          </a>

          <a className="d-flex align-items-left card-text">
            <div>Container URI</div>
          </a>
          <a className="d-flex align-items-right card-text">
            <Badge variant="warning" onClick={this.copyProfileURI}>
              <div ref={this.profileURI} className="profileURI">
                { "registry.beta.floxdev.com" + // this.props.registryURI +
                  "/" +
                  this.props.variant.proOwner +
                  "/" +
                  this.props.variant.proName}
              </div>
            </Badge>
          </a>

          {this.props.nextUpgrade && (
            <a className="d-flex justify-content-between card-text">
              <div>Next upgrade</div>
              <div>
                <Moment format="Do MMM YYYY HH:mm">
                  {this.props.nextUpgrade}
                </Moment>
              </div>
            </a>
          )}
        </Card.Body>
      </Card>
    );
  }
}

export default ProfilePackagesCard;
