import React from 'react';
import Card from 'react-bootstrap/Card';

class NoDerivationsInstalled extends React.Component {
  render() {
    return (
      <Card bg="light">
        <Card.Body>
          <Card.Text>
            There are no derivations installed to this profile.
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
}

export default NoDerivationsInstalled;
