import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import InputGroup from "react-bootstrap/InputGroup";
import { getVariant, editProfile } from "../utils";
import { Redirect } from "react-router-dom";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: this.props.name,
      policy: this.props.policy,
      description: this.props.variant.description,
      profileTaken: false,
      profileFree: false,
      enableContainer: true, // should be this.props.container
      upgradeProfile: "",
      upgradeProfileID: this.props.upgradeFrom,
      upgradeProfileValid: false,
      upgradeProfileInvalid: false,
      redirect: false,
    };
  }

  componentDidMount = () => {
    this.getUpgradeProfile();
  };

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleNameChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
      profileFree: false,
    });
  };

  getUpgradeProfile = () => {
    this.props.upgradeFrom &&
      this.setState({
        upgradeProfile:
          this.props.upgradeFrom.proOwner +
          "/" +
          this.props.upgradeFrom.proName,
      });
  };

  nameChanged = () => {
    if (this.props.name !== this.state.profile) {
      return true;
    }
    return false;
  };

  policyChanged = () => {
    if (this.props.policy !== this.state.policy) {
      return true;
    }
    return false;
  };

  upgradeProfileChanged = () => {
    if (this.props.upgradeFrom !== this.state.upgradeProfileID) {
      return true;
    }
    return false;
  };

  descChanged = () => {
    if (this.props.variant.description !== this.state.description) {
      return true;
    }
    return false;
  };

  validChange = () => {
    if (this.policyChanged() && !this.nameChanged()) {
      return true;
    }
    if (this.policyChanged() && this.nameChanged() && this.state.profileFree) {
      return true;
    }
    if (this.nameChanged() && this.state.profileFree) {
      return true;
    }
    if (this.upgradeProfileChanged() && this.state.upgradeProfileValid) {
      return true;
    }
    if (this.descChanged()) {
      return true;
    }
    return false;
  };

  checkProfile = () => {
    this.setState({ profileTaken: false, profileFree: false });
    if (this.props.name === this.state.profile) {
      this.setState({ profileFree: true });
    } else {
      getVariant(this.props.variant.proOwner, this.state.profile).then(
        (res) => {
          if (res.status === 200) {
            this.setState({ profileTaken: true, profileFree: false });
          } else {
            this.setState({ profileTaken: false, profileFree: true });
          }
        }
      );
    }
  };

  checkUpgradeProfile = () => {
    var res = this.state.upgradeProfile.split("/");
    if (res.length !== 2) {
      this.setState({
        upgradeProfileValid: false,
        upgradeProfileInvalid: true,
      });
      return;
    }
    getVariant(res[0], res[1]).then((res) => {
      if (res.status === 200) {
        this.setState({
          upgradeProfileValid: true,
          upgradeProfileInvalid: false,
        });
        res.json().then((data) => {
          this.setState({ upgradeProfileID: data.proID });
        });
      } else {
        this.setState({
          upgradeProfileValid: false,
          upgradeProfileInvalid: true,
        });
      }
    });
  };

  editProfile = () => {
    editProfile(
      this.props.variant.proID,
      this.props.owner,
      this.state.profile,
      this.state.policy,
      this.state.upgradeProfileID,
      this.state.description,
      this.props.variant.synchronous
    ).then((res) => {
      if (res.status === 200) {
        this.setState({ redirect: true });
      }
    });
  };

  render() {
    const { owner, admin, policies, variant } = this.props;
    const {
      profile,
      profileTaken,
      description,
      policy,
      profileValid,
      profileFree,
      redirect,
      upgradeProfileInvalid,
      upgradeProfileValid,
      enableContainer,
      upgradeProfile,
    } = this.state;

    return (
      <div>
        <h5>General</h5>
        <hr />
        <Row>
          <Col md={4}>
            <Form.Label className="label">Profile Owner</Form.Label>
            <Form.Control disabled type="text" value={owner} />
          </Col>
          <Col md={5}>
            <Form.Label className="label">Profile Name</Form.Label>
            <InputGroup>
              <Form.Control
                disabled={!admin}
                id="profile"
                type="text"
                value={profile}
                onChange={this.handleNameChange}
              />
              <InputGroup.Append>
                <Button
                  onClick={this.checkProfile}
                  disabled={!admin}
                  size="sm"
                  variant={profileTaken ? "outline-danger" : "outline-success"}
                >
                  Check
                </Button>
              </InputGroup.Append>
              <InputGroup.Append>
                <div className="divider" />
                {profileFree && (
                  <div className="d-flex align-items-center">&#10004;</div>
                )}
                {profileTaken && (
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center">&#10006;</div>
                  </div>
                )}
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={9}>
            <Form.Label>
              <div className="label">Add a profile description</div>
            </Form.Label>
            <Form.Group controlId="description">
              <Form.Control
                as="textarea"
                rows="2"
                onChange={this.handleChange}
                value={description}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <Form.Label>
              <div className="label">Upgrade policy</div>
            </Form.Label>
            <Form.Group controlId="policy">
              <Form.Control
                disabled={!admin}
                as="select"
                value={policy}
                onChange={this.handleChange}
              >
                {policies.map((policy) => (
                  <option value={policy.name}>{policy.name}</option>
                ))}
                ;
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={9}>
            <Form.Check
              type="switch"
              id="enableContainer"
              defaultChecked={enableContainer}
              onChange={this.handleChange}
              label="Enable container builds"
            />
          </Col>
        </Row>

        <br />
        <h5>Upgrade from profile</h5>
        <hr />
        <p>
          You can configure a profile to be upgraded from another profile. This
          means that when a profile is to be upgraded (according to the time
          determined by its policy), the upgrade action will copy the contents
          of the profile pointed to, rather than simply upgrading each
          individual package.
        </p>
        <p>
          This mechanism is useful for implementing experimental -> qa ->
          production pipelines.
        </p>
        <br />
        <InputGroup>
          <Form.Control
            id="upgradeProfile"
            disabled={!admin}
            type="text"
            placeholder="Enter name"
            value={upgradeProfile}
            onChange={this.handleChange}
            autocomplete="off"
          />
          <InputGroup.Append>
            <Button
              onClick={this.checkUpgradeProfile}
              disabled={!admin}
              size="sm"
              variant={profileValid ? "outline-danger" : "outline-success"}
            >
              Check
            </Button>
          </InputGroup.Append>
          <InputGroup.Append>
            <Button
              onClick={() =>
                this.setState({
                  upgradeProfileID: 0,
                  upgradeProfile: "",
                  upgradeProfileValid: true,
                  upgradeProfileInvalid: false,
                })
              }
              size="sm"
              disabled={!admin}
              variant="outline-danger"
            >
              Remove
            </Button>
          </InputGroup.Append>
          <InputGroup.Append>
            <div className="divider" />
            {upgradeProfileValid && (
              <div className="d-flex align-items-center">&#10004;</div>
            )}
            {upgradeProfileInvalid && (
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">&#10006;</div>
              </div>
            )}
          </InputGroup.Append>
        </InputGroup>
        <br />

        <div className="d-flex align-items-center">
          <h5>Synchronous Profile Updates</h5>
          <div className="divider" />
          <Badge variant="secondary">beta</Badge>
        </div>
        <hr />
        <p>
          Enabling synchronous profile updates means FloxPM will ensure a new
          generation's packages are downloaded to each host where this profile
          is pinned <i>before</i> activating the profile globally.
        </p>
        <Form>
          <Form.Check
            custom
            disabled
            type="switch"
            id="custom-switch"
            defaultChecked={variant.synchronous}
            label="Disabled"
          />
        </Form>

        {admin && (
          <div>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                size="sm"
                disabled={!this.validChange()}
                onClick={this.editProfile}
                variant="success"
              >
                Save Changes
              </Button>
            </div>
          </div>
        )}
        {redirect && <Redirect to="/" />}
      </div>
    );
  }
}

export default Settings;
