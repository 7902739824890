import React from 'react';
import Moment from 'react-moment';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';

const spinner = (
  <Spinner animation="border" role="status">
    <span className="sr-only">Loading...</span>
  </Spinner>
);

class ConfirmUpgradesTable extends React.Component {
  formatTime = time => {
    return (
      <Moment unix format="Do MMM YYYY HH:mm">
        {time}
      </Moment>
    );
  };

  render() {
    if (this.props.upgrades.length === 0) {
      return spinner;
    }
    return (
      <Table size="sm" striped bordered>
        <thead>
          <tr>
            <th>Package Name</th>
            <th>Current</th>
            <th>Latest</th>
          </tr>
        </thead>
        <tbody>
          {this.props.upgrades.map(drv => (
            <tr>
              <td>
                {drv.current.chnName}.{drv.current.pkgName}
              </td>
              <td>{drv.current.drvVersion}</td>
              <td>{drv.latest.drvVersion}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export default ConfirmUpgradesTable;
