import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UpgradePoliciesTable from "./UpgradePoliciesTable";
import GlobalStateContext from "../GlobalState";

class Policies extends React.Component {
  static contextType = GlobalStateContext;
  render() {
    const { policies } = this.context;
    return (
      <div>
        {this.props.navBar()}
        <br />
        <Container>
          <Row>
            <Col>
              <h2>Upgrade Policies</h2>
            </Col>
          </Row>
          <hr />
          <br />
          <Row>
            <Col md={8}>
              <p>
                Upgrade policies are applied to profiles such that we assign, on
                profile creation, a mechanism to determine how a profile's
                installed packages are upgraded over time as new packages become
                available.
              </p>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <UpgradePoliciesTable policies={policies} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Policies;
