import React from "react";
import Moment from "react-moment";
import GlobalStateContext from "../GlobalState";

class Footer extends React.Component {
  static contextType = GlobalStateContext;

  render() {
    const { user, authusers } = this.props;
    const { config } = this.context;
    var version = config ? config.version : "unknown";
    return (
      <footer>
        <hr />
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <div className="small">
              {"You are authenticated as " + user + "."}
            </div>
            {authusers &&
              authusers.map((user) => (
                <div className="small">
                  <span>
                    {"You are temporarily authenticated as " +
                      user.username +
                      " (expires in "}
                    <Moment fromNow>{user.expiry}</Moment>
                    {")"}
                  </span>
                </div>
              ))}
          </div>
          <small>{"FloxPM (" + version + ")"}</small>
          <div className="divider" />
          <div>
            <a className="small" target="_blank" href={config.docsURL}>
              Read the docs
            </a>
            <div className="large-divider" />
            {config.variant === "flox" && (
              <div>
                <a
                  className="small"
                  target="_blank"
                  href="https://discourse.floxdev.com"
                >
                  Join the conversation
                </a>
                <div className="large-divider" />
                <a
                  className="small"
                  target="_blank"
                  href="https://flox-legal.s3.eu-west-2.amazonaws.com/flox-license.pdf"
                >
                  Terms and conditions
                </a>
              </div>
            )}
            <br />
          </div>
        </div>
        <br />
      </footer>
    );
  }
}

export default Footer;
