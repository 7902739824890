import React from 'react';
import Table from 'react-bootstrap/Table';
import Moment from 'react-moment';
import { CVEBadgeByProfile } from '../common/CVEBadge';

const formatTime = (time) => {
  return <Moment format="Do MMM YYYY HH:mm">{time}</Moment>;
};

const GenerationsTable = ({ clickGen, current, generations, owner, name }) => {
  if (!generations) {
    return null;
  }

  return (
    <Table size="sm" striped bordered>
      <thead>
        <tr>
          <th>Timestamp</th>
          <th>Generation</th>
          <th>Security</th>
          <th>Comment</th>
        </tr>
      </thead>
      <tbody>
        {generations.map(gen => (
          <tr className={gen.generation === current ? 'table-success' : ''}>
            <td
              className="link-text"
              onClick={clickGen(gen)}>
              {formatTime(gen.time)}
            </td>
            <td>{gen.generation}</td>
            <td>
              <CVEBadgeByProfile
                owner={owner}
                name={name}
                gen={gen.generation}
              />
            </td>
            <td>{gen.comment}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default GenerationsTable;
