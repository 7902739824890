import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ render, loggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={loggedIn ? render : () => <Redirect to="/login" />}
    />
  );
};

export default ProtectedRoute;
