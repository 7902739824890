import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import GlobalStateContext from "../GlobalState";
import PackagesList from "./PackagesList";
import "react-virtualized/styles.css";

import { getAuthProfiles } from "../utils";

class Packages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packages: [],
      profiles: [],
      selectAll: true,
      showLibs: false,
      determined: false,
    };
  }

  static contextType = GlobalStateContext;

  componentDidMount = () => {
    this.getProfiles(this.context.user.username);
  };

  getChannels = () => {
    this.context.channels.map((chan) =>
      this.setState(
        { [chan.name]: this.state.selectAll },
        this.determinePackages
      )
    );
  };

  getProfiles = (owner) => {
    getAuthProfiles(owner).then((res) => {
      if (res.ok) {
        res
          .json()
          .then((res) => this.setState({ profiles: res.variants }))
          .catch((err) => console.log(err));
      }
    });
  };

  selectAll = () => {
    this.context.channels.map((chan) =>
      this.setState({ [chan.name]: !this.state.selectAll })
    );
    this.setState({ selectAll: !this.state.selectAll }, this.determinePackages);
  };

  clickChan = (chan) => {
    this.setState({ [chan]: !this.state[chan] }, this.determinePackages);
  };

  determinePackages = () => {
    this.setState({
      packages: this.context.packages.filter(
        (pkg) => this.state[pkg.channel.name]
      ),
    });
  };

  render() {
    const { user, channels, gotPackages } = this.context;
    const { packages, determined } = this.state;
    const username = user.username;
    var chanNames = channels.map((chn) => chn.name);

    // Once the packages API call has completed, we need to determine which
    // channels are selected and then filter the local packages list
    // appropriately.
    if (gotPackages && !determined) {
      this.getChannels();
      this.setState({ determined: true });
    }

    if (!channels) {
      return null;
    }

    return (
      <div>
        {this.props.navBar()}
        <br />
        <Container>
          <br />
          <Row>
            <Col sm={2}>
              <div>
                <b>Channels</b>
              </div>
              <div className="vertical-divider" />
              <Form.Check
                type="checkbox"
                label="Select All"
                onClick={this.selectAll}
                checked={this.state.selectAll}
              />
              <hr />
              {[...new Set(chanNames)].map((chan) => (
                <Form.Check
                  type="checkbox"
                  id={chan}
                  label={chan}
                  onClick={() => this.clickChan(chan)}
                  checked={this.state[chan]}
                />
              ))}
            </Col>
            <Col>
              {gotPackages && (
                <PackagesList
                  packages={this.state.packages}
                  profiles={this.state.profiles}
                  user={username}
                />
              )}
              {!gotPackages && (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Packages;
