import React from 'react';
import Button from 'react-bootstrap/Button';

class ProfileSettingsButton extends React.Component {
  render() {
    return (
      <Button
        size="sm"
        variant="outline-dangers"
        href={
          '/profiles/' + this.props.owner + '/' + this.props.name + '/settings'
        }>
        <i className="material-icons">settings_applications</i>
      </Button>
    );
  }
}
export default ProfileSettingsButton;
