import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";

class EditDerivationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priority: this.props.derivation.priority
    };
  }

  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  render() {
    const {
      outputs,
      derivation,
      show,
      close,
      name,
      setPriority,
      removeDerivation,
      removeNixPath
    } = this.props;
    const { priority } = this.state;
    const anon = derivation.drvID ? false : true;
    const showOutputs = outputs.length > 1 ? true : false;
    return (
      <Modal show={show} onHide={close}>
        <Modal.Header className="modal-background" closeButton>
          <Modal.Title>{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {derivation.drvID && (
            <div className="d-flex align-items-center">
              <Col md={6}>
                <div>Priority</div>
                <small>Modify this package's priority.</small>
              </Col>
              <Col>
                <div className="d-flex justify-content-end w-50">
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="priority"
                      type="number"
                      onChange={this.handleChange}
                      value={priority ? priority : derivation.priority}
                    />
                    <InputGroup.Append>
                      <Button
                        disabled={
                          !priority || Number(priority) === derivation.priority
                        }
                        onClick={() => setPriority(Number(priority))}
                        variant="outline-success"
                      >
                        &#10004;
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </div>
              </Col>
            </div>
          )}
          {!anon && <hr />}
          <div className="d-flex align-items-center">
            <Col md={7}>
              <div className="danger">Remove Package</div>
              <small>
                Remove this package and all its outputs from profile.
              </small>
            </Col>
            <Col>
              <div className="d-flex justify-content-center">
                <Button
                  onClick={
                    derivation.drvID
                      ? removeDerivation
                      : removeNixPath(derivation.nixStoreKey)
                  }
                  size="sm"
                  variant="outline-danger"
                >
                  Remove
                </Button>
              </div>
            </Col>
          </div>
          {!anon && showOutputs && (
            <div>
              <br />
              <div className="d-flex align-items-center">
                <Col>
                  <small>
                    Remove individual derivation outputs belonging to this
                    package.
                  </small>
                </Col>
              </div>
              <br />
              <ListGroup variant="flush">
                {outputs.map(out => (
                  <ListGroup.Item className="py-1">
                    <div className="d-flex align-items-center">
                      <Col>
                        <div>{out.outType ? out.outType : "out"}</div>
                      </Col>
                      <Col>
                        <div>{out.nixStoreKey.slice(0, 7)}</div>
                      </Col>
                      <Col>
                        <i
                          onClick={removeNixPath(out.nixStoreKey)}
                          className="material-icons click"
                        >
                          delete
                        </i>
                      </Col>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditDerivationModal;
