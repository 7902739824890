import React from "react";
import GenerationsTable from "./GenerationsTable";
import GenerationModal from "./GenerationModal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { rollback, getDerivations, getRequisites } from "../utils";

class Generations extends React.Component {
  owner = this.props.owner;
  name = this.props.name;

  constructor(props) {
    super(props);
    this.state = {
      derivations: [],
      requisites: [],
      gen: {},
      show: false,
      showDeleted: false,
      mode: ""
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.gen.generation !== prevState.gen.generation) {
      this.getDerivations(this.state.gen.generation);
      this.getRequisites(this.state.gen.generation);
    }
  };

  getDerivations = gen => {
    getDerivations(this.owner, this.name, gen)
      .then(response => response.json())
      .then(data => this.setState({ derivations: data }));
  };

  getRequisites = gen => {
    getRequisites(this.owner, this.name, gen).then(res => {
      if (res.ok) {
        res.json().then(data =>
          this.setState({
            requisites: data.requisites ? data.requisites : []
          })
        );
      }
    });
  };

  rollback = () => {
    rollback(this.owner, this.name, this.state.gen.generation).then(res => {
      if (res.ok) {
        this.setState({ mode: "", show: false });
        this.props.getVariant();
        this.props.getUpgrades();
      } else {
        this.setState({ mode: "failure" });
      }
    });
  };

  authenticate = () => {
    this.setState({ show: false, mode: "" });
    this.props.authenticate();
  };

  render() {
    const { variant, admin, generations } = this.props;
    const {
      showDeleted,
      requisites,
      show,
      gen,
      mode,
      derivations
    } = this.state;

    if (!variant || variant.pviID === 0) {
      return null;
    }

    if (generations.length === 0) {
      return (
        <div>
          <Card bg="light">
            <Card.Body>
              <Card.Text>There are no generations for this profile.</Card.Text>
            </Card.Body>
          </Card>
        </div>
      );
    }
    return (
      <div>
        <Row>
          <Col>
            <Form>
              <Form.Check
                type="checkbox"
                label="Show Deleted Generations"
                id="output"
                onClick={event =>
                  this.setState({
                    showDeleted: event.target.checked
                  })
                }
              ></Form.Check>
            </Form>
          </Col>
        </Row>
        <br />
        <GenerationsTable
          generations={generations.filter(
            gen =>
              (showDeleted && gen.deleted) || (!showDeleted && !gen.deleted)
          )}
          clickGen={gen => () => this.setState({ gen: gen, show: true })}
          showDeleted={showDeleted}
          owner={this.owner}
          name={this.name}
          current={variant.gen}
        />
        <GenerationModal
          show={show}
          gen={gen}
          mode={mode}
          admin={admin}
          derivations={derivations}
          close={() => this.setState({ show: false, mode: "" })}
          rollback={this.rollback}
          owner={this.owner}
          name={this.name}
          current={variant.gen}
          requisites={requisites}
          authenticate={this.authenticate}
        />
      </div>
    );
  }
}

export default Generations;
