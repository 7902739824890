import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { deleteUser } from "./../utils";

class DeleteAccountConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "",
      error: "",
      user: ""
    };
  }

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  delete = () => {
    deleteUser()
      .then(res => {
        if (res.ok) {
          this.props.success();
          this.setState({ profile: "" });
        } else {
          this.setState({ mode: "failure", error: res.error });
        }
      })
      .catch(err => console.error(err));
  };

  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.close}>
          <Modal.Header className="modal-background" closeButton>
            <Modal.Title>{this.props.user}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              Are you sure you want to delete your account? Please type in your
              username to confirm.
            </div>
            <br />
            <Form>
              <InputGroup>
                <Form.Control
                  id="user"
                  type="text"
                  value={this.state.user}
                  onChange={this.handleChange}
                />
                <InputGroup.Append>
                  <Button
                    type="submit"
                    disabled={this.state.user !== this.props.user}
                    variant="danger"
                    onClick={this.delete}
                    size="sm"
                  >
                    Delete
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default DeleteAccountConfirm;
