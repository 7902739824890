import React from "react";
import Moment from "react-moment";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";

class RecentBuilds extends React.Component {
  render() {
    return (
      <Card border="secondary">
        <Card.Body>
          <Card.Title>Recent Builds</Card.Title>
          <ListGroup variant="flush">
            {this.props.drvs.map((drv) => (
              <ListGroup.Item
                variant={this.props.selected == drv.drvID ? "success" : ""}
              >
                <div className="d-flex justify-content-between">
                  <Link to={"/derivation/" + drv.drvID + "/" + drv.pkgVarID}>
                    {drv.drvID}
                  </Link>
                  <small>
                    <Moment unix format="Do MMM YYYY HH:mm">
                      {drv.drvTimestamp}
                    </Moment>
                  </small>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>
    );
  }
}

export default RecentBuilds;
