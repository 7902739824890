import React from 'react';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';

class ChangeLog extends React.Component {
  formatTime = time => (
    <Moment className="small" format="Do MMM YYYY HH:mm:ss">
      {time}
    </Moment>
  );

  render() {
    if (!this.props.comments) {
      return null;
    }
    return this.props.comments.map(comm => {
      return (
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Row>
              <Col xs={4}>
                <div className="d-flex justify-content-start">
                  {this.formatTime(comm.timestamp)}
                </div>
              </Col>
              <Col xs={8}>
                <div>{comm.comment}</div>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      );
    });
  }
}

export default ChangeLog;
