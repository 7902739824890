import React from "react";
import GenerationDerivationsTable from "../generation-derivations/GenerationDerivationsTable";
import DerivationOutputsTable from "./DerivationOutputsTable";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import Moment from "react-moment";
import SbomHref from "../common/SbomHref";
import GlobalStateContext from "../GlobalState";
import { hydraUrlToSbomUrl } from "../utils";

class GenerationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ""
    };
  }

  static contextType = GlobalStateContext;

  getName = () => {
    return this.props.owner + "/" + this.props.name;
  };

  getSbomUrl = (path) => {
    return hydraUrlToSbomUrl(this.context.config.hydraURL) + path;
  };

  getSbomHref = (path) => {
    return SbomHref(this.context.config.hydraURL, path);
  };

  rollbackButton = admin => {
    if (this.props.mode === "") {
      return (
        <Button
          onClick={admin ? this.props.rollback : this.props.authenticate}
          variant="success"
          size="sm"
        >
          Switch
        </Button>
      );
    }
  };

  failureAlert = () => (
    <Alert variant="danger" show={this.props.mode === "failure"}>
      <p>Failed to change generation</p>
    </Alert>
  );

  modal = (active, requisites, gen, derivations, admin) => (
    <Modal
      size="xl"
      show={this.props.show}
      onHide={this.props.close}
      scrollable
    >
      <Modal.Header className="align-items-center" closeButton>
        <Modal.Title>
          {this.getName()}-{gen.generation}-link
        </Modal.Title>
        <div className="large-divider" />
        {active && <Badge variant="success">Active</Badge>}
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="table">
          <Tab eventKey="table" title="Derivations">
            <br />
            <GenerationDerivationsTable
              derivations={derivations}
              getSbomHref={this.getSbomHref}
            />
            {this.failureAlert()}
          </Tab>
          <Tab eventKey="outputs" title="Outputs">
            <br />
            <DerivationOutputsTable derivations={derivations} />
          </Tab>
          <Tab eventKey="info" title="Information">
            <br />
            <Row>
              <Col md={3}>
                <b>Pvi ID</b>
              </Col>
              <Col>{gen.pviID}</Col>
            </Row>
            <Row>
              <Col md={3}>
                <b>Last Active</b>
              </Col>
              <Col>
                <Moment format="Do MMM YYYY HH:mm">{gen.lastActive}</Moment>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <b>Created</b>
              </Col>
              <Col>
                <Moment format="Do MMM YYYY HH:mm">{gen.time}</Moment>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <b>Deleted</b>
              </Col>
              <Col>{gen.deleted ? "True" : "False"}</Col>
            </Row>
            <Row>
              <Col md={3}>
                <b>Environment</b>
              </Col>
              <Col>{this.getSbomHref(gen.userEnvironment)}</Col>
            </Row>
          </Tab>
          <Tab eventKey="requisites" title="Requisites">
            <br />
            <Col md={6}>
              <Form.Control
                id="search"
                type="text"
                placeholder={"Search " + requisites.length + " requisites ..."}
                autocomplete="off"
                onChange={event =>
                  this.setState({ [event.target.id]: event.target.value })
                }
              ></Form.Control>
            </Col>
            <hr />
            <ListGroup variant="flush">
              {requisites.sort().map(req => (
                <ListGroup.Item className="py-1">{this.getSbomHref(req)}</ListGroup.Item>
              ))}
            </ListGroup>
          </Tab>

          <Tab eventKey="sbom" title="SBOM">
            <br />
              <div dangerouslySetInnerHTML={{ __html:
                "<iframe width='100%' height='1500px' frameborder='0' src='" +
                this.getSbomUrl("/nix/profiles/" + this.getName() + "-" + gen.generation + "-link") +
		"' />"
              }} />
          </Tab>

        </Tabs>
      </Modal.Body>
      {!active && <Modal.Footer>{this.rollbackButton(admin)}</Modal.Footer>}
    </Modal>
  );

  render() {
    var { requisites } = this.props;
    const { gen, current, derivations, admin } = this.props;
    const { search } = this.state;

    if (search !== "") {
      requisites = requisites.filter(req => req.includes(search));
    }

    if (gen !== 0) {
      return this.modal(
        gen.generation === current,
        requisites,
        gen,
        derivations,
        admin
      );
    }
    return null;
  }
}

export default GenerationModal;
