import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Profiles from "./profiles/Profiles";
import Downloads from "./downloads/Downloads";
import Settings from "./settings/Settings";
import UserProfiles from "./profiles/UserProfiles";
import Packages from "./packages/Packages";
import Policies from "./policies/Policies";
import ProfilePackages from "./profile-packages/ProfilePackages";
import DerivationInfo from "./derivation-info/DerivationInfo";
import CreateProfile from "./create-profile/CreateProfile";
import LogoutNavigationBar from "./LogoutNavigationBar";
import NavigationBar from "./NavigationBar";
import Login from "./login/Login";
import ProtectedRoute from "./ProtectedRoute";
import GlobalStateContext from "./GlobalState";
import {
  getUser,
  getConfig,
  getPolicies,
  getChannels,
  getSystems,
  getCookie,
  getProfiles,
  getSubscriptions,
  getAllPackages,
  getSyncUpdates,
  getActivity
} from "./utils";

import "./custom.css";

class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      loginComplete: false,
      user: {},
      systems: [],
      policies: [],
      channels: [],
      profiles: [],
      subscriptions: [],
      activity: [],
      packages: [],
      syncUpdates: [],
      gotPackages: false,
      config: {},
      cookie: "",
      ws: null
    };
  }

  getUser = () => {
    !this.state.loginComplete &&
      getUser().then(res => {
        if (res.ok) {
          res.json().then(res =>
            this.setState({
              loggedIn: true,
              loginComplete: true,
              user: res
            })
          );
        } else {
          this.setState({ loginComplete: true });
        }
      });
  };

  getConfig = () => {
    getConfig()
      .then(res => res.json())
      .then(res => {
        this.setState({ config: res });
        // Only establish websocket for updates if a wsURL has been specified.
        res.wsURL && this.getUpdatesWS(res.wsURL);
      })
      .catch(err => console.log(err));
  };

  updateUser = () => {
    getUser().then(res => {
      if (res.ok) {
        res.json().then(res =>
          this.setState({
            user: res
          })
        );
      }
    });
  };

  getPackages = () => {
    getAllPackages()
      .then(response => response.json())
      .then(res => {
        this.setState({ packages: res.packages, gotPackages: true });
      })
      .catch(err => console.log(err));
  };

  getPolicies = () => {
    getPolicies()
      .then(res => res.ok && res.json())
      .then(res => res.policies && this.setState({ policies: res.policies }))
      .catch(err => console.log(err));
  };

  getChannels = () => {
    getChannels()
      .then(response => response.json())
      .then(res => res.channels && this.setState({ channels: res.channels }))
      .catch(err => console.log(err));
  };

  getSystems = () => {
    getSystems()
      .then(res => res.json())
      .then(res => res.systems && this.setState({ systems: res.systems }))
      .catch(err => console.log(err));
  };

  getCookie = () => {
    getCookie()
      .then(res => res.json())
      .then(res => this.setState({ cookie: res.token }))
      .catch(err => console.log(err));
  };

  getProfiles = () => {
    getProfiles()
      .then(res => res.ok && res.json())
      .then(data => data.variants && this.setState({ profiles: data.variants }))
      .catch(err => console.log(err));
  };

  getSubscriptions = () => {
    getSubscriptions()
      .then(res => res.ok && res.json())
      .then(data => {
        this.setState({ subscriptions: data.variants ? data.variants : [] });
      })
      .catch(err => console.log(err));
  };

  getActivity = () => {
    getActivity()
      .then(res => res.ok && res.json())
      .then(data => {
        this.setState({ activity: data.activity ? data.activity : [] });
      })
      .catch(err => console.log(err));
  };

  getSyncUpdates = () => {
    getSyncUpdates()
      .then(res => res.ok && res.json())
      .then(data => {
        this.setState({ syncUpdates: data.profiles ? data.profiles : [] });
      })
      .catch(err => console.log(err));
  };

  getUpdatesWS = url => {
    var ws = new WebSocket(url + "/api/wsUpdates");
    var connectInterval;

    ws.onopen = () => {
      console.log("websocket connection established");
      this.setState({ ws: ws });
      clearTimeout(connectInterval);
    };

    ws.onclose = () => {
      console.log("websocket connection closed");
      connectInterval = setTimeout(this.check(url), 10000);
    };

    ws.onmessage = e => {
      try {
        const msg = JSON.parse(e.data);

        switch (msg.type) {
          case "packageAdded":
            this.getPackages();
            break;
          case "profilesUpdated":
            this.getProfiles();
            break;
          case "syncUpdates":
            this.setState({ syncUpdates: msg.syncUpdates.profiles });
            break;
          default:
            console.log("unknown activity type");
        }
      } catch (e) {
        console.error(e);
      }
    };
  };

  check = url => () => {
    const { ws } = this.state;
    if (!ws || ws.readyState == WebSocket.CLOSED) this.getUpdatesWS(url);
  };

  loginSuccess = () => {
    this.setState({ loggedIn: true, loginComplete: true });
  };

  componentDidMount = () => {
    this.getUser();
    this.getConfig();
    this.getProfiles();
    this.getSubscriptions();
    this.getActivity();
    this.getPolicies();
    this.getChannels();
    this.getCookie();
    this.getSystems();
    this.getPackages();
    this.getSyncUpdates();
  };

  homeRedirect = loggedIn => {
    if (this.state.config.authType !== "token") {
      return () => <Redirect to={"/profiles"} />;
    }
    return () => <Redirect to={loggedIn ? "/profiles" : "/login"} />;
  };

  login = loggedIn => {
    if (loggedIn) {
      return () => <Redirect to={"/profiles"} />;
    }
    return () => <Login loginSuccess={this.loginSuccess} />;
  };

  navBar = props =>
    this.state.config.authType === "token" ? (
      <LogoutNavigationBar
        {...props}
        user={this.state.user}
        logout={() => this.setState({ loggedIn: false })}
      />
    ) : (
      <NavigationBar {...props} user={this.state.user.username} />
    );

  withNavbar = (WrappedComponent, navBar) => {
    return props => <WrappedComponent {...props} navBar={navBar} />;
  };

  render() {
    const {
      loggedIn,
      loginComplete,
      user,
      config,
      policies,
      channels,
      systems,
      cookie,
      profiles,
      subscriptions,
      activity,
      packages,
      gotPackages,
      syncUpdates
    } = this.state;
    var globalState = {
      user,
      info: config,
      config,
      policies,
      channels,
      systems,
      cookie,
      profiles,
      subscriptions,
      activity,
      getSubscriptions: this.getSubscriptions,
      updateProfiles: this.getProfiles,
      packages,
      gotPackages,
      updateUser: this.updateUser,
      syncUpdates
    };

    if (loginComplete) {
      return (
        <GlobalStateContext.Provider value={globalState}>
          <Router>
            <Route path="/" exact render={this.homeRedirect(loggedIn)} />
            <Route path="/login" exact render={this.login(loggedIn)} />
            <ProtectedRoute
              render={this.withNavbar(Profiles, this.navBar)}
              loggedIn={loggedIn}
              path="/profiles"
              exact
            />
            <ProtectedRoute
              render={this.withNavbar(Profiles, this.navBar)}
              loggedIn={loggedIn}
              path="/sync/:uuid"
              exact
            />
            {config.variant === "flox" && (
              <ProtectedRoute
                render={this.withNavbar(Downloads, this.navBar)}
                loggedIn={loggedIn}
                path="/install"
                exact
              />
            )}
            {config.variant === "flox" && (
              <ProtectedRoute
                render={this.withNavbar(Settings, this.navBar)}
                loggedIn={loggedIn}
                path="/settings"
                exact
              />
            )}
            <ProtectedRoute
              render={this.withNavbar(Packages, this.navBar)}
              loggedIn={loggedIn}
              path="/packages"
              exact
            />
            <ProtectedRoute
              render={this.withNavbar(Packages, this.navBar)}
              loggedIn={loggedIn}
              path="/packages/:channel"
            />
            <ProtectedRoute
              path="/profiles/:pvrID/generations"
              loggedIn={loggedIn}
              render={this.withNavbar(ProfilePackages, this.navBar)}
            />
            <ProtectedRoute
              path="/profiles/:owner/:profile/:mode"
              loggedIn={loggedIn}
              render={this.withNavbar(ProfilePackages, this.navBar)}
            />
            <ProtectedRoute
              path="/users/:user"
              loggedIn={loggedIn}
              render={this.withNavbar(UserProfiles, this.navBar)}
            />
            <ProtectedRoute
              path="/derivation/:id/:pkgVarID"
              loggedIn={loggedIn}
              render={this.withNavbar(DerivationInfo, this.navBar)}
            />
            <ProtectedRoute
              path="/create/profile/"
              loggedIn={loggedIn}
              exact
              render={this.withNavbar(CreateProfile, this.navBar)}
            />
            <ProtectedRoute
              path="/policies"
              loggedIn={loggedIn}
              render={this.withNavbar(Policies, this.navBar)}
            />
            <ProtectedRoute
              path="/upgrades/:pvrID"
              loggedIn={loggedIn}
              render={this.withNavbar(ProfilePackages, this.navBar)}
            />
          </Router>
        </GlobalStateContext.Provider>
      );
    }
    return null;
  }
}

export default AppRouter;
