import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { joinSyncUpdateWS } from "../utils";
import Moment from "react-moment";
import GlobalStateContext from "../GlobalState";

class JoinSyncUpdateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hosts: [],
      search: "",
      loading: true,
      inProgress: true,
      finished: false,
      summary: null,
      ws: {},
      ackHosts: [],
      totalHosts: 0,
      completedHosts: [],
      failedHosts: [],
      backloggedHosts: [],
    };
  }

  static contextType = GlobalStateContext;

  getGeneration = () => {
    return (
      this.state.summary.profile.proOwner +
      "/" +
      this.state.summary.profile.proName +
      "/" +
      this.state.summary.profile.gen
    );
  };

  joinSyncUpdate = (uuid) => {
    var ws = joinSyncUpdateWS(this.context.config.wsURL, uuid);

    ws.onmessage = (e) => {
      var update = JSON.parse(e.data);
      update.ack &&
        this.setState({
          ackHosts: this.state.ackHosts.concat(update.ack),
        });
      update.confirm &&
        this.setState({
          completedHosts: this.state.completedHosts.concat(update.confirm),
        });
      update.failed &&
        this.setState({
          failedHosts: this.state.failedHosts.concat(update.failed),
        });
      update.backlogged &&
        this.setState({
          backloggedHosts: this.state.backloggedHosts.concat(update.backlogged),
        });
      update.total &&
        this.setState({
          totalHosts: update.total,
        });
      update.summary &&
        this.setState({
          summary: update.summary,
          finished: true,
          inProgress: false,
          completedHosts: update.summary.completedHosts
            ? update.summary.completedHosts
            : [],
        });
    };
    this.setState({ inProgress: true, ws: ws });
  };

  resetState = () => {
    this.setState({
      inProgress: false,
      finished: false,
      ws: {},
      ackHosts: [],
      completedHosts: [],
      failedHosts: [],
      backloggedHosts: [],
      totalHosts: 0,
    });
  };

  componentDidMount() {
    if (this.props.show) {
      this.joinSyncUpdate(this.props.uuid);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.show !== prevProps.show ||
      this.props.uuid !== prevProps.uuid
    ) {
      this.joinSyncUpdate(this.props.uuid);
    }
  }

  closeModal = () => {
    this.state.ws.readyState && this.state.ws.close();
    this.resetState();
    this.props.close();
  };

  getDownloadHosts = () => {
    return this.state.ackHosts.filter(
      (host) => !this.state.completedHosts.includes(host)
    );
  };

  getBackloggedHosts = () => {
    return this.state.backloggedHosts.filter(
      (host) => !this.state.ackHosts.includes(host)
    );
  };

  updateSummaryHosts = (summary) => {
    return (
      <div>
        <Row className="align-items-center">
          <Col sm={6}>
            <Form.Control
              id="search"
              type="text"
              placeholder="Search hosts ..."
              autocomplete="off"
              onChange={this.handleChange}
            ></Form.Control>
          </Col>
        </Row>
        <br />
        <Row>
          {summary.completedHosts && (
            <Col md={6}>
              <b>Completed</b>
              <ListGroup>
                {summary.completedHosts
                  .filter(this.filterSearch)
                  .sort()
                  .map((host) => (
                    <ListGroup variant="flush">
                      <ListGroup.Item className="py-1">
                        <div className="d-flex justify-content-between">
                          <div>{host}</div>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  ))}
              </ListGroup>
            </Col>
          )}
          <Col md={6}>
            {summary.failedHosts && (
              <div>
                <b>Failed</b>
                <ListGroup>
                  {summary.failedHosts
                    .filter(this.filterSearch)
                    .sort()
                    .map((host) => (
                      <ListGroup variant="flush">
                        <ListGroup.Item className="py-1">
                          <div className="d-flex justify-content-between">
                            <div>{host}</div>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    ))}
                </ListGroup>
                <br />
              </div>
            )}
            {summary.timedOutHosts && (
              <div>
                <b>Timed out</b>
                <ListGroup>
                  {summary.timedOutHosts
                    .filter(this.filterSearch)
                    .sort()
                    .map((host) => (
                      <ListGroup variant="flush">
                        <ListGroup.Item className="py-1">
                          <div className="d-flex justify-content-between">
                            <div>{host}</div>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    ))}
                </ListGroup>
                <br />
              </div>
            )}
            {summary.backloggedHosts && (
              <div>
                <b>Backlogged</b>
                <ListGroup>
                  {summary.backloggedHosts
                    .filter(this.filterSearch)
                    .sort()
                    .map((host) => (
                      <ListGroup variant="flush">
                        <ListGroup.Item className="py-1">
                          <div className="d-flex justify-content-between">
                            <div>{host}</div>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    ))}
                </ListGroup>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  };

  // Split column view of hosts which have/have not completed the download.
  getUpdateSummary = (completedHosts, downloadHosts, backloggedHosts) => (
    <Row>
      <Col md={6}>
        {completedHosts.length != 0 && (
          <div>
            <b>Completed</b>
            <ListGroup>
              {this.state.completedHosts.sort().map((host) => (
                <ListGroup variant="flush">
                  <ListGroup.Item className="py-1">
                    <div className="d-flex justify-content-between">
                      <div>{host}</div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              ))}
            </ListGroup>
          </div>
        )}
        <br />
        {this.state.failedHosts.length != 0 && (
          <div>
            <b>Failed</b>
            <ListGroup>
              {this.state.failedHosts.sort().map((host) => (
                <ListGroup variant="flush">
                  <ListGroup.Item className="py-1">
                    <div className="d-flex justify-content-between">
                      <div>{host}</div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              ))}
            </ListGroup>
          </div>
        )}
      </Col>
      <Col md={6}>
        {downloadHosts.length != 0 && (
          <div>
            <b>Downloading</b>
            <ListGroup>
              {downloadHosts.sort().map((host) => (
                <ListGroup variant="flush">
                  <ListGroup.Item className="py-1">
                    <div className="d-flex justify-content-between">
                      <div>{host}</div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              ))}
            </ListGroup>
            <br />
          </div>
        )}
        {backloggedHosts.length != 0 && (
          <div>
            <b>Backlogged</b>
            <ListGroup>
              {backloggedHosts.sort().map((host) => (
                <ListGroup variant="flush">
                  <ListGroup.Item className="py-1">
                    <div className="d-flex justify-content-between">
                      <div>{host}</div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              ))}
            </ListGroup>
          </div>
        )}
      </Col>
    </Row>
  );

  updateSummary = (summary) => {
    return (
      <div>
        <Row>
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <div>Completed hosts</div>
              </Col>
              <Col>
                <div>{summary.complete ? summary.complete : 0}</div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div>Failed hosts</div>
              </Col>
              <Col>
                <div>{summary.failed ? summary.failed : 0}</div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div>Timed out</div>
              </Col>
              <Col>
                <div>
                  {summary.ack -
                    ((summary.complete ? summary.complete : 0) +
                      (summary.failed ? summary.failed : 0))}
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div>Completed</div>
              </Col>
              <Col>
                <div>{summary.comp ? "True" : "False"}</div>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <div>Start time</div>
              </Col>
              <Col>
                <Moment format="Do MMM YYYY HH:mm:ss">{summary.start}</Moment>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div>Finish time</div>
              </Col>
              <Col>
                <Moment format="Do MMM YYYY HH:mm:ss">{summary.finish}</Moment>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
      </div>
    );
  };

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  filterSearch = (host) => {
    return this.state.search === "" || host.includes(this.state.search);
  };

  render() {
    var numHosts = this.state.hosts ? this.state.hosts.length : 0;
    var downloadHosts = this.getDownloadHosts();
    var backloggedHosts = this.getBackloggedHosts();
    const { show, owner, name } = this.props;
    const { wsURL } = this.context.config;
    const {
      inProgress,
      finished,
      completedHosts,
      failedHosts,
      ackHosts,
      totalHosts,
      loading,
      hosts,
      summary,
    } = this.state;

    const admin = owner === this.context.user.username;

    return (
      <Modal size="lg" show={show} onHide={this.closeModal} scrollable>
        <Modal.Header closeButton>
          {inProgress && (
            <Modal.Title>
              <div className="d-flex align-items-center">
                Update In Progress
                <div className="large-divider" />
                <div>
                  {completedHosts.length + failedHosts.length}/{totalHosts}
                </div>
                <div className="large-divider" />
                <Spinner animation="border" />
              </div>
            </Modal.Title>
          )}
          {!inProgress && finished && (
            <Modal.Title>
              {"Update Summary (" + this.getGeneration() + " )"}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {finished && this.updateSummary(summary)}
          {finished && this.updateSummaryHosts(summary)}
          {!finished && (
            <div>
              <Row className="align-items-center">
                <Col sm={6}>
                  <Form.Control
                    id="search"
                    type="text"
                    placeholder="Search hosts ..."
                    autocomplete="off"
                    onChange={this.handleChange}
                  ></Form.Control>
                </Col>
              </Row>
              <br />
            </div>
          )}
          {inProgress &&
            this.getUpdateSummary(
              completedHosts,
              downloadHosts,
              backloggedHosts
            )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default JoinSyncUpdateModal;
