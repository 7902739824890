import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

import ProfilesTable from "./ProfilesTable";
import ProfileCreateButton from "./ProfileCreateButton";
import AddPackagesButton from "../common/AddPackagesButton";
import Form from "react-bootstrap/Form";
import DeleteProfileConfirm from "../delete-profile-confirm/DeleteProfileConfirm";
import Activity from "./Activity";
import SyncUpdatesList from "./SyncUpdatesList";
import SyncUpdateSummary from "../common/SyncUpdateSummary";
import JoinSyncUpdateModal from "../upgrades/JoinSyncUpdateModal";
import PopularPackages from "./PopularPackages";
import Footer from "../common/Footer";
import GlobalStateContext from "../GlobalState";
import { SecurityDashboard } from "../containers/SecurityDashboard";

import { getPopular, subscribe, unsubscribe } from "../utils";

class Profiles extends React.Component {
  syncUpdateID = this.props.match.params.uuid;

  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
      policy: "All",
      selected: {},
      popular: [],
      show: false,
      syncProf: {},
      hideAlert: false,
      search: ""
    };
  }

  static contextType = GlobalStateContext;

  click = event => {
    this.setState({ showAll: event.target.checked });
  };

  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  getPopular = () => {
    getPopular()
      .then(res => res.ok && res.json())
      .then(data => data.packages && this.setState({ popular: data.packages }))
      .catch(err => console.log(err));
  };

  subscribe = (owner, name) => {
    subscribe(owner, name)
      .then(res => {
        if (res.ok) {
          this.context.getSubscriptions();
        }
      })
      .catch(err => console.log(err));
  };

  unsubscribe = (owner, name) => {
    unsubscribe(owner, name)
      .then(res => {
        if (res.ok) {
          this.context.getSubscriptions();
        }
      })
      .catch(err => console.log(err));
  };

  hasProfile = username => {
    if (this.context.profiles && this.context.profiles.length == 0) {
      return true;
    }
    return this.context.profiles.some(prof => prof.proOwner === username);
  };

  filterPolicy = prof => {
    return this.state.policy === "All" || prof.uppName == this.state.policy;
  };

  filterSearch = prof => {
    return (
      this.state.search === "" ||
      [prof.proOwner, prof.proName].join("/").includes(this.state.search)
    );
  };

  getMyProfiles = (prof, user) => {
    return (
      this.state.showAll ||
      this.state.search !== "" ||
      prof.proOwner === user ||
      (this.context.subscriptions
        ? this.context.subscriptions
            .map(prof => prof.proID)
            .includes(prof.proID)
        : false)
    );
  };

  getFilteredProfile = user => {
    return this.context.profiles
      .filter(prof => this.getMyProfiles(prof, user))
      .filter(prof => this.filterPolicy(prof))
      .filter(prof => this.filterSearch(prof));
  };

  joinSyncUpdate = (uuid, prof) => {
    this.props.history.push("/sync/" + uuid);
  };

  render() {
    const {
      syncProf,
      uuid,
      hideAlert,
      policy,
      selected,
      show,
      popular
    } = this.state;

    const {
      user,
      policies,
      info,
      profiles,
      config,
      subscriptions,
      syncUpdates,
      activity
    } = this.context;
    const username = user.username;

    return (
      <div>
        {this.props.navBar()}
        <br />
        <Container>
          <Row className="align-items-center">
            <Col md={2}>
              <div className="heading">Profiles</div>
            </Col>
            <Col md={8}>
              <Alert
                show={!this.hasProfile(username) && !hideAlert}
                onClose={() => this.setState({ hideAlert: true })}
                variant="info"
                dismissible
              >
                {config.variant === "flox" && (
                  <div>
                    <b>
                      New to Flox? Start{" "}
                      <a
                        className="highlighted"
                        href="/docs/index.html"
                        target="_blank"
                      >
                        here
                      </a>{" "}
                      to see what you can do with it.
                    </b>
                  </div>
                )}
                {config.variant === "desco" && (
                  <b>
                    You don't have any profiles yet. Why not create one using
                    the button below?
                  </b>
                )}
              </Alert>
            </Col>
          </Row>
          <hr />
          <div className="d-flex flex-row align-items-center">
            <div>
              <Form.Check
                type="checkbox"
                label={
                  "Show All (" + (profiles.length ? profiles.length : 0) + ")"
                }
                onClick={this.click}
              />
            </div>
            <div className="large-divider" />
            <div>
              <Form.Control
                id="search"
                type="text"
                placeholder="Search ..."
                autocomplete="off"
                onChange={this.handleChange}
              ></Form.Control>
            </div>
            <div className="large-divider" />
            <div className="mr-auto">
              <Form.Control
                id="policy"
                as="select"
                onChange={this.handleChange}
                value={policy}
              >
                <option>All</option>
                {policies &&
                  policies.map(pol => <option>{pol.name}</option>)}{" "}
              </Form.Control>
            </div>
            <div>
              <ProfileCreateButton />
              <div className="divider" />
              <AddPackagesButton />
            </div>
          </div>
          <br />
          <Row>
            <Col>
              <ProfilesTable
                selected={prof => () =>
                  this.setState({ selected: prof, show: true })}
                profiles={this.getFilteredProfile(username)}
                subscriptions={
                  subscriptions ? subscriptions.map(prof => prof.proID) : []
                }
                user={username}
                subscribe={this.subscribe}
                unsubscribe={this.unsubscribe}
                variant={config.variant}
              />
            </Col>
          </Row>
          <DeleteProfileConfirm
            owner={selected.proOwner}
            name={selected.proName}
            show={show}
            close={() => this.setState({ show: false, selected: {} })}
            success={() => {
              this.setState({ show: false, selected: {} });
              this.context.updateProfiles();
            }}
          />
          <br />
          <hr />
          <Row>
            <Col md={6}>
              {activity && activity.length !== 0 && (
                <div>
                  <div className="heading">Recent Activity</div>
                  <div className="vertical-divider" />
                  <Activity
                    user={username}
                    activity={activity}
                    subscriptions={
                      subscriptions
                        ? subscriptions.map(
                            prof => prof.proOwner + "/" + prof.proName
                          )
                        : []
                    }
                  />
                </div>
              )}
            </Col>
            {/* <Col sm={6}>
              <SecurityDashboard />
            </Col> */}
          </Row>
          <Row>
            <Col md={6}>
              {popular &&
                popular.length !== 0 &&
                false(
                  <div>
                    <div className="heading">Popular</div>
                    <div className="vertical-divider" />
                    <PopularPackages popular={popular} />
                    <br />
                  </div>
                )}
              {syncUpdates.length > 0 && (
                <div>
                  <div className="small-heading">Synchronous Updates</div>
                  <div className="vertical-divider" />
                  <SyncUpdatesList
                    updates={syncUpdates}
                    select={this.joinSyncUpdate}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Footer user={username} authusers={user.authusers} info={info} />
        </Container>
        <JoinSyncUpdateModal
          show={this.syncUpdateID ? true : false}
          owner={syncProf.proOwner}
          name={syncProf.proName}
          gen={syncProf.gen}
          uuid={this.syncUpdateID}
          close={() => this.props.history.push("/profiles")}
        />
      </div>
    );
  }
}

export default Profiles;
