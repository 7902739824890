import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import Footer from "../common/Footer";
import Nav from "react-bootstrap/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ProfilePackagesCard from "./ProfilePackagesCard";
import SyncInfo from "./SyncInfo";
import ActiveHosts from "./ActiveHosts";
import AuthenticateButton from "../common/AuthenticateButton";
import AuthenticateModal from "../common/AuthenticateModal";
import Generations from "../generations/Generations";
import ChangeLog from "./ChangeLog";
import CurrentGenerationListGroup from "./CurrentGenerationListGroup";
import Upgrades from "../upgrades/Upgrades";
import Settings from "./Settings";
import GlobalStateContext from "../GlobalState";

import {
  getUpgrades,
  getGenerations,
  getDerivations,
  getVariant,
  getProfileVariantHistory,
  isAuthorised,
  getPackageHolds,
  getProfileHosts,
} from "../utils";

class ProfilePackages extends React.Component {
  pvrID = Number(this.props.match.params.pvrID);
  owner = this.props.match.params.owner;
  profile = this.props.match.params.profile;
  mode = this.props.match.params.mode;

  constructor(props) {
    super(props);
    this.state = {
      packages: [],
      generations: [],
      derivations: [],
      variant: {},
      history: [],
      holds: [],
      hosts: [],
      upgrades: [],
      oldPackages: [],
      newPackages: [],
      upToDate: false,
      showOutPaths: false,
      show: false,
      showActiveHosts: false,
    };
  }

  static contextType = GlobalStateContext;

  componentDidMount = () => {
    this.getVariant();
  };

  getGenerations = (owner, profile) => {
    getGenerations(owner, profile)
      .then((response) => response.json())
      .then((data) => this.setState({ generations: data }))
      .catch((err) => console.log(err));
  };

  getDerivations = (owner, name, gen) => {
    getDerivations(owner, name, gen)
      .then((response) => response.json())
      .then((data) => this.setState({ derivations: data }))
      .catch((err) => console.log(err));
  };

  getHistory = (pvrID) => {
    getProfileVariantHistory(pvrID)
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => this.setState({ history: data.comments }));
        }
      })
      .catch((err) => console.log(err));
  };

  getVariant = () => {
    getVariant(this.owner, this.profile)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ variant: data });
        this.getGenerations(data.proOwner, data.proName);
        this.getHistory(data.pvrID);
        this.getPackageHolds(data.proOwner, data.proName);
        this.getUpgrades();
        if (data.gen) {
          this.getDerivations(data.proOwner, data.proName, data.gen);
        }
      })
      .catch((err) => console.log(err));
  };

  getPackageHolds = (owner, name) => {
    getPackageHolds(owner, name)
      .then((res) => {
        if (res.ok) {
          res
            .json()
            .then((data) =>
              this.setState({ holds: data.derivations ? data.derivations : [] })
            );
        }
      })
      .catch((err) => console.log(err));
  };

  getProfileHosts = (owner, name) => {
    getProfileHosts(owner, name)
      .then((res) => {
        if (res.ok) {
          console.log("here");
          res
            .json()
            .then((data) =>
              this.setState({
                hosts: data.hosts ? data.hosts : [],
                showActiveHosts: true,
              })
            )
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  getUpgrades = () => {
    getUpgrades(this.owner, this.profile)
      .then((response) => response.json())
      .then((data) => {
        if (data.variants.length === 1) {
          var variant = data.variants[0];
          if (variant.upgrades || variant.old || variant.new) {
            this.setState({
              upgrades: variant.upgrades,
              oldPackages: variant.old,
              newPackages: variant.new,
            });
          } else {
            this.setState({ upToDate: true });
          }
        } else {
          this.setState({ upToDate: true });
        }
      })
      .catch((err) => console.error(err));
  };

  click = (event) => {
    this.setState({ showOutPaths: event.target.checked });
  };

  getName = () => {
    return this.owner + "/" + this.profile;
  };

  getBreadcrumb = () => {
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Profiles</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/users/" + this.owner}>{this.owner}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{this.profile}</Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  render() {
    const { user, info, policies, updateUser, config } = this.context;
    const username = user.username;

    var authorised = isAuthorised(username, user.authUsers, this.owner);

    const {
      holds,
      variant,
      upgrades,
      oldPackages,
      newPackages,
      upToDate,
      generations,
      showActiveHosts,
      hosts,
    } = this.state;

    const syncUpdatesEnabled = variant.synchronous && config.wsURL !== "";

    return (
      <div>
        {this.props.navBar()}
        <br />
        <Container>
          <Row>
            <Col md={5}>{this.getBreadcrumb()}</Col>
          </Row>
          <hr />
          <br />
          <Row>
            <Col md={4}>
              <ProfilePackagesCard
                name={this.getName()}
                packages={
                  this.state.derivations ? this.state.derivations.length : 0
                }
                generations={this.state.generations.length}
                nextUpgrade={variant.nextUpgrade}
                currGen={variant.gen ? variant.gen : 0}
                variant={variant}
              />
              {syncUpdatesEnabled && (
                <div>
                  <br />
                  <hr />
                  <SyncInfo owner={variant.proOwner} name={variant.proName} />
                  <hr />
                </div>
              )}
              {!syncUpdatesEnabled && <br />}
              {config.authType === "kerberos" && !authorised && (
                <AuthenticateButton
                  onClick={() => this.setState({ show: true })}
                />
              )}
              <div
                className="link-text small"
                onClick={() =>
                  this.getProfileHosts(variant.proOwner, variant.proName)
                }
              >
                View hosts where this profile is being used
              </div>
            </Col>
            <Col md={8}>
              {variant.pvrID && (
                <Tab.Container defaultActiveKey={this.mode}>
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="status">Current Generation</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="generations">Generations</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="upgrades">Upgrades</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="changelog">Change Log</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="settings">Settings</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <br />
                  <Tab.Content>
                    <Tab.Pane eventKey="status">
                      {this.state.derivations.length !== 0 && (
                        <div>
                          <Row>
                            <Col>
                              <Form>
                                <Form.Check
                                  type="checkbox"
                                  label="Show Output Paths"
                                  id="output"
                                  onClick={this.click}
                                ></Form.Check>
                              </Form>
                            </Col>
                          </Row>
                          <br />
                        </div>
                      )}
                      <CurrentGenerationListGroup
                        owner={this.owner}
                        profile={this.profile}
                        derivations={this.state.derivations}
                        update={this.getVariant}
                        showOutPaths={this.state.showOutPaths}
                        pvrID={variant.pvrID}
                        admin={authorised}
                        holds={holds ? holds.map((hold) => hold.dvoID) : []}
                        authenticate={() => this.setState({ show: true })}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="generations">
                      <Generations
                        host={this.host}
                        variant={variant}
                        getVariant={this.getVariant}
                        owner={this.owner}
                        name={this.profile}
                        admin={authorised}
                        getUpgrades={this.getUpgrades}
                        generations={generations}
                        getGenerations={() =>
                          this.getGenerations(this.owner, this.profile)
                        }
                        authenticate={() => this.setState({ show: true })}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="upgrades">
                      <Upgrades
                        pvrID={this.pvrID}
                        owner={this.owner}
                        name={this.profile}
                        getVariant={this.getVariant}
                        variant={variant}
                        admin={authorised}
                        getUpgrades={this.getUpgrades}
                        upgrades={upgrades}
                        oldPackages={oldPackages}
                        newPackages={newPackages}
                        upToDate={upToDate}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="changelog">
                      <ChangeLog comments={this.state.history} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="settings">
                      <Settings
                        owner={this.owner}
                        name={this.profile}
                        policies={policies}
                        policy={variant.uppName}
                        variant={variant}
                        getVariant={this.getVariant}
                        upgradeFrom={variant.upgradeFrom}
                        admin={authorised}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              )}
            </Col>
          </Row>
          <Footer user={username} authusers={user.authUsers} info={info} />
        </Container>
        <AuthenticateModal
          show={this.state.show}
          owner={this.owner}
          name={this.profile}
          update={() => {
            updateUser();
            this.setState({ show: false });
          }}
        />
        <ActiveHosts
          show={showActiveHosts}
          owner={this.owner}
          name={this.profile}
          hosts={hosts}
          closeModal={() => this.setState({ showActiveHosts: false })}
        />
      </div>
    );
  }
}

export default ProfilePackages;
