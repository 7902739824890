import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Footer from "../common/Footer";
import DerivationInfoTable from "./DerivationInfoTable";
import DerivationInfoCard from "./DerivationInfoCard";
import RecentBuilds from "./RecentBuilds";
import InstallModal from "../derivations/InstallModal";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import SbomSearchHref from "../common/SbomSearchHref";
import GlobalStateContext from "../GlobalState";
import { Link, Redirect } from "react-router-dom";
import {
  getDerivation,
  getLatestDerivation,
  getDerivationOutputs,
  getPackageDerivations,
  getPackageVariants,
  getAuthProfiles,
  installDerivation,
} from "../utils";

class DerivationInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drv: [],
      outputs: [],
      drvs: [],
      selectedDrv: {},
      profiles: [],
      variants: [],
      systemID: 0,
      showMore: false,
      showAll: false,
      showModal: false,
    };
  }

  static contextType = GlobalStateContext;

  getSbomSearchHref = (key, path) => {
    return SbomSearchHref(this.context.config.hydraURL, key, path);
  };

  getDerivation = (drvID, pkgVarID) => {
    getDerivation(drvID, pkgVarID)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ drv: data, system: data.systemName });
        this.getDerivationOutputs(data.drvID);
        this.getDerivations(
          data.chnName,
          data.jobset,
          data.pkgName,
          this.state.systemID
        );
        this.getPackageVariants(data.chnName, data.jobset, data.pkgName);
      })
      .catch((err) => console.log(err));
  };

  getDerivationOutputs = (drvID) => {
    getDerivationOutputs(drvID).then((res) => {
      if (res.ok) {
        res
          .json()
          .then((res) => this.setState({ outputs: res.derivations }))
          .catch((err) => {
            console.log(err);
            this.setState({ outputs: [] });
          });
      }
    });
  };

  getDerivations = (channel, jobset, pkg, systemID) => {
    getPackageDerivations(channel, jobset, pkg, systemID)
      .then((response) => response.json())
      .then((data) => this.setState({ drvs: data }))
      .catch((err) => console.log(err));
  };

  getPackageVariants = (channel, jobset, pkg) => {
    getPackageVariants(channel, jobset, pkg)
      .then((response) => response.json())
      .then((data) => this.setState({ variants: data.variants }))
      .catch((err) => console.log(err));
  };

  getProfiles = (owner) => {
    getAuthProfiles(owner).then((res) => {
      if (res.ok) {
        res
          .json()
          .then((data) => this.setState({ profiles: data.variants }))
          .catch((err) => console.log(err));
      }
    });
  };

  getLatestDerivation = (proj, job, pkg, systemID) => {
    getLatestDerivation(proj, job, pkg, systemID)
      .then((res) => res.json())
      .then((res) =>
        this.setState({
          redirect: true,
          redirectDrvID: res.drvID,
          redirectPkgVarID: res.pkgVarID,
        })
      )
      .catch((err) => console.log(err));
  };

  installDerivation = (pro, drv) =>
    installDerivation(pro.proOwner, pro.proName, drv.drvID, drv.pkgVarID);

  componentDidMount = () => {
    this.getDerivation(
      this.props.match.params.id,
      this.props.match.params.pkgVarID
    );
    this.getProfiles(this.context.user.username);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getDerivation(
        this.props.match.params.id,
        this.props.match.params.pkgVarID
      );
    }
    if (prevState.systemID != this.state.systemID) {
      this.getLatestDerivation(
        this.state.drv.chnName,
        this.state.drv.jobset,
        this.state.drv.pkgName,
        this.state.systemID
      );
    }
  };

  getBreadcrumb = (drv) => (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to={"/packages/" + drv.chnName}>{drv.chnName}</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item active>{drv.pkgName}</Breadcrumb.Item>
      <Breadcrumb.Item active>{drv.drvID}</Breadcrumb.Item>
    </Breadcrumb>
  );

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    const {
      profiles,
      drv,
      drvs,
      outputs,
      showMore,
      showModal,
      showAll,
      variants,
      redirect,
      redirectDrvID,
      redirectPkgVarID,
    } = this.state;

    const { user, info, config } = this.context;
    const username = user.username;

    var show = showAll ? drvs.length : showMore ? 10 : 5;
    var isLib = !drv.hasBin && !drv.hasMan ? true : false;

    return (
      <div>
        {this.props.navBar()}
        <br />
        <Container>
          <Row>
            <Col md={5}>{this.getBreadcrumb(drv)}</Col>
          </Row>
          <hr />
          <br />
          <div className="d-flex flex-row align-items-center">
            <div>
              <Badge variant="info">{drv.jobset}</Badge>
            </div>
            <div className="divider" />
            <div className="package-heading">
              {this.getSbomSearchHref(drv.pkgName, drv.chnName + "." + drv.pkgName)}
            </div>
            <div className="large-divider" />
            <div className="large-divider" />
            <div>
              <Form.Control
                as="select"
                id="systemID"
                onChange={this.handleChange}
              >
                {variants &&
                  variants.map((vars) => (
                    <option value={vars.systemID}>{vars.systemName}</option>
                  ))}
              </Form.Control>
            </div>
            {isLib && (
              <div className="ml-auto">
                <Badge variant="info">library</Badge>
              </div>
            )}
          </div>
          <br />
          <Row>
            <Col md={4}>
              <DerivationInfoCard
                name={drv.chnName + "." + drv.pkgName}
                pkgID={drv.pkgID}
                buildNumber={drv.drvBuildNumber}
                buildTime={drv.drvTimestamp}
                info={drv}
                jobset={drv.jobset}
                hydraURL={config.hydraURL}
              />
              <br />
              <RecentBuilds drvs={drvs.slice(0, show)} selected={drv.drvID} />
            </Col>
            <Col>
              <DerivationInfoTable
                info={drv}
                outputs={outputs}
                defaults={
                  drv.defaultOutputs
                    ? drv.defaultOutputs.split(",").map((out) => out.trim())
                    : ["out"]
                }
              />
              <br />
              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  size="sm"
                  variant="outline-info"
                  onClick={() =>
                    this.setState({ showModal: true, selectedDrv: drv })
                  }
                >
                  Install
                </Button>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              {drvs.length > 5 && !showMore && !showAll && (
                <Link onClick={() => this.setState({ showMore: true })}>
                  Show More
                </Link>
              )}
              {showMore && drvs.length > 5 && (
                <Link
                  onClick={() =>
                    this.setState({ showAll: true, showMore: false })
                  }
                >
                  {"Show all " + drvs.length + " builds"}
                </Link>
              )}
              {showAll && (
                <Link onClick={() => this.setState({ showAll: false })}>
                  Show Less
                </Link>
              )}
            </Col>
          </Row>
          <Footer user={username} authusers={user.authUsers} info={info} />
        </Container>
        <InstallModal
          install={this.installDerivation}
          show={showModal}
          profiles={profiles}
          drv={drv}
          channel={drv.chnName}
          package={drv.pkgName}
          close={() => this.setState({ showModal: false })}
        />
        {redirect && (
          <Redirect
            to={"/derivation/" + redirectDrvID + "/" + redirectPkgVarID}
          />
        )}
      </div>
    );
  }
}

export default DerivationInfo;
