import React from "react";
import Moment from "react-moment";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";

class DerivationInfoCard extends React.Component {
  formatTime = (time) => {
    return (
      <Moment unix format="Do MMM YYYY HH:mm">
        {time}
      </Moment>
    );
  };

  render() {
    const { info } = this.props;
    return (
      <Card bg="light" border="secondary">
        <Card.Body>
          <a className="d-flex justify-content-between card-text">
            <div>Drv ID</div>
            <div>{this.props.info.drvID}</div>
          </a>
          <a className="d-flex justify-content-between card-text">
            <div>Pkg ID</div>
            <div>{this.props.pkgID}</div>
          </a>
          <hr />
          <a className="d-flex justify-content-between card-text">
            <div>Build Number</div>
            <a href={this.props.hydraURL + this.props.buildNumber}>
              {this.props.buildNumber}
            </a>
          </a>
          <a className="d-flex justify-content-between card-text">
            <div>Build Time</div>
            {this.formatTime(this.props.info.drvTimestamp)}
          </a>
          <hr />
          <a className="d-flex justify-content-between card-text">
            <div>Version</div>
            <div>{this.props.info.drvVersion}</div>
          </a>
          <a className="d-flex justify-content-between card-text">
            <div>Default Outputs</div>
            <div>
              {this.props.info.defaultOutputs
                ? this.props.info.defaultOutputs
                : "out"}
            </div>
          </a>
          <a className="d-flex justify-content-between card-text">
            <div>System</div>
            <div>{info.systemName}</div>
          </a>
        </Card.Body>
      </Card>
    );
  }
}

export default DerivationInfoCard;
