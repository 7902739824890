import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import GlobalStateContext from "../GlobalState";

class ActivityListGroup extends React.Component {
  static contextType = GlobalStateContext;

  getComment = (comment) => {
    if (comment.length > 200) {
      return <div className="comment">{comment.substring(0, 200)}</div>;
    }
    return <div className="comment">{comment}</div>;
  };

  render() {
    if (!this.props.activity || this.props.activity.length === 0) {
      return (
        <div className="d-flex flex-column">
          <br />
          <h5>There's no recent activity</h5>
          <p>
            When a profile you own or have subscribed to updates for changes,
            you'll be notified here.
          </p>
        </div>
      );
    }
    return (
      <ListGroup variant="flush">
        {this.props.activity.map((entry) => (
          <ListGroup.Item>
            <div className="d-flex flex-row">
              <Col md={2}>
                <Image
                  src={
                    this.context.config.variant === "desco"
                      ? "https://employeeprofile/api/employees/" +
                        entry.proOwner +
                        "/photo?useDefaultPhoto=true"
                      : entry.avatarURL
                  }
                  fluid
                />
              </Col>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <Link
                    to={
                      "/profiles/" +
                      entry.proOwner +
                      "/" +
                      entry.proName +
                      "/changelog"
                    }
                  >
                    {entry.proOwner + "/" + entry.proName}
                  </Link>
                  <div className="small-divider" />
                  <div>&bull;</div>
                  <div className="small-divider" />
                  <div className="date">
                    <Moment fromNow>{entry.timestamp}</Moment>
                  </div>
                </div>
                <small>{this.getComment(entry.comment)}</small>
              </div>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  }
}

export default ActivityListGroup;
