import React from "react";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getSyncHostsProfile } from "../utils";

class ActiveHosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hosts: [],
      search: "",
    };
  }

  filterSearch = (host) => {
    return this.state.search === "" || host.includes(this.state.search);
  };

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  render() {
    const { hosts, show } = this.props;
    return (
      <Modal size="lg" show={show} onHide={this.props.closeModal} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>
            {hosts && hosts.length} hosts are using to this profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-center">
            <Col sm={6}>
              <Form.Control
                id="search"
                type="text"
                placeholder="Search hosts ..."
                autocomplete="off"
                onChange={this.handleChange}
              ></Form.Control>
            </Col>
          </Row>
          <br />
          {hosts &&
            hosts
              .filter(this.filterSearch)
              .sort()
              .map((host) => (
                <ListGroup variant="flush">
                  <ListGroup.Item className="py-1">
                    <div>{host}</div>
                  </ListGroup.Item>
                </ListGroup>
              ))}
        </Modal.Body>
      </Modal>
    );
  }
}

export default ActiveHosts;
