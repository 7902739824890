import React from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { getCVEbyCPEs, getRequisites, parseNixStorePath } from "../utils";

const getScore = (data) => {
  if (data.hasOwnProperty("Nvds") && data.Nvds.length > 0) {
    if (
      data.Nvds[0].hasOwnProperty("Cvss3") &&
      data.Nvds[0].Cvss3.hasOwnProperty("BaseScore")
    ) {
      return data.Nvds[0].Cvss3.BaseScore;
    }

    if (
      data.Nvds[0].hasOwnProperty("Cvss2") &&
      data.Nvds[0].Cvss2.hasOwnProperty("BaseScore")
    ) {
      return data.Nvds[0].Cvss2.BaseScore;
    }
  }

  return 0;
};

const CVEBadge = ({ data, name }) => {
  const [show, setShow] = React.useState(false);

  if (data === null) {
    return (
      <React.Fragment>
        <Spinner animation="border" size="sm" variant="secondary" />
      </React.Fragment>
    );
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const processedData = data.map((item) => ({
    cveId: item.CveID,
    item,
    score: getScore(item),
  }));

  processedData.sort((a, b) => (a.score > b.score ? -1 : 1));

  const maxScore = Math.max(...processedData.map((item) => item.score));

  return (
    <React.Fragment>
      <Badge
        onClick={handleShow}
        // https://coolors.co/4cbb17-fff44f-ffae42-ff8c00-ff0000
        style={{
          color:
            maxScore >= 9.0
              ? "#ffffff"
              : maxScore >= 7.0
              ? "#ffffff"
              : maxScore >= 4.0
              ? "#000000"
              : maxScore > 0.1
              ? "#000000"
              : "#ffffff",
          backgroundColor:
            maxScore >= 9.0
              ? "#ff0000"
              : maxScore >= 7.0
              ? "#ff8c00"
              : maxScore >= 4.0
              ? "#ffae42"
              : maxScore > 0.1
              ? "#fff44f"
              : "#4cbb17",
        }}
      >
        {data.length > 0 ? "❢" : "✔"}
      </Badge>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Known vulnerabilities for {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data.length > 0 ? (
            <ul>
              {processedData.map((item) => (
                <li>
                  <a
                    href={`https://nvd.nist.gov/vuln/detail/${item.cveId}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {item.cveId} (CVSS {item.score})
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <span>None until now</span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const CVEBadgeByCPE = ({ vendor, product, version }) => {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    async function effect() {
      const response = await getCVEbyCPEs([{ vendor, product, version }]);

      if (response.ok) {
        const responseData = await response.json();

        setData(responseData);
      } else {
        setData([]);
      }
    }

    effect();
  }, []);

  return <CVEBadge data={data} name={`${product} ${version}`} />;
};

const CVEBadgeByNixStorePath = ({ nixStorePath }) => {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    async function effect() {
      const parsed = parseNixStorePath(nixStorePath);
      const response = await getCVEbyCPEs([
        {
          vendor: parsed.name,
          product: parsed.name,
          version: parsed.version,
        },
      ]);

      if (response.ok) {
        const responseData = await response.json();

        setData(responseData);
      } else {
        setData([]);
      }
    }

    effect();
  }, []);

  return <CVEBadge data={data} name={nixStorePath} />;
};

const CVEBadgeByProfile = ({ owner, name, gen }) => {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    async function effect() {
      const requisitesResponse = await getRequisites(owner, name, gen);

      if (requisitesResponse.ok) {
        const requisitesResponseData = await requisitesResponse.json();

        const response = await getCVEbyCPEs(
          requisitesResponseData.requisites
            .map(parseNixStorePath)
            .map(({ name, version }) => ({
              vendor: name,
              product: name,
              version,
            }))
        );

        if (response.ok) {
          const responseData = await response.json();

          setData(responseData);
        } else {
          setData([]);
        }
      }
    }

    effect();
  }, []);

  return <CVEBadge data={data} name={`profile ${owner}/${name}/${gen}`} />;
};

const CVEBadgeByReferences = ({ name, references }) => {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    async function effect() {
      if (references !== null) {
        const response = await getCVEbyCPEs(
          references
            .filter(Boolean)
            .map(parseNixStorePath)
            .map(({ name, version }) => ({
              vendor: name,
              product: name,
              version,
            }))
        );

        if (response.ok) {
          const responseData = await response.json();

          setData(responseData);
        } else {
          setData([]);
        }
      }
    }

    effect();
  }, [references]);

  return <CVEBadge data={data} name={`Runtime dependencies of ${name}`} />;
};

export {
  CVEBadgeByCPE,
  CVEBadgeByNixStorePath,
  CVEBadgeByProfile,
  CVEBadgeByReferences,
};
