import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProfilesTable from "./ProfilesTable";
import ProfileCreateButton from "./ProfileCreateButton";
import AddPackagesButton from "../common/AddPackagesButton";
import DeleteProfileConfirm from "../delete-profile-confirm/DeleteProfileConfirm";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import GlobalStateContext from "../GlobalState";
import { Link } from "react-router-dom";

import { getUserProfiles, getSubscriptions } from "../utils";

class UserProfiles extends React.Component {
  profileUser = this.props.match.params.user;

  constructor(props) {
    super(props);
    this.state = {
      profiles: [],
      showAll: false,
      subscriptions: [],
      selected: {},
      show: false,
    };
  }

  static contextType = GlobalStateContext;

  getBreadcrumb = () => {
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Profiles</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{this.profileUser}</Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  componentDidMount = () => {
    this.getSubscriptions();
    this.getProfiles();
  };

  click = (event) => {
    this.setState({ showAll: event.target.checked });
  };

  getSubscriptions = () => {
    getSubscriptions()
      .then((response) => response.json())
      .then((data) => {
        data.variants
          ? this.setState({ subscriptions: data.variants })
          : this.setState({ subscriptions: [] });
      })
      .catch((err) => console.log(err));
  };

  getProfiles = () => {
    getUserProfiles(this.profileUser)
      .then((response) => response.json())
      .then((data) => this.setState({ profiles: data.variants }))
      .catch((err) => console.log(err));
  };

  render() {
    const { showAll, selected, show, profiles, subscriptions } = this.state;
    const { username } = this.context.user;

    return (
      <div>
        {this.props.navBar()}
        <br />
        <Container>
          <Row>
            <Col md={5}>{this.getBreadcrumb()}</Col>
          </Row>
          <br />
          <Row>
            <Col>
              <ProfilesTable
                selected={(prof) => () =>
                  this.setState({ selected: prof, show: true })}
                profiles={profiles}
                subscriptions={subscriptions.map((prof) => prof.proID)}
                showAll={showAll}
                user={username}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <ProfileCreateButton />
            <div className="divider" />
            <AddPackagesButton />
          </Row>
          <DeleteProfileConfirm
            owner={selected.proOwner}
            name={selected.proName}
            show={show}
            close={() => this.setState({ show: false, selected: {} })}
            success={() => {
              this.setState({ show: false, selected: {} });
              this.getProfiles();
            }}
          />
        </Container>
      </div>
    );
  }
}

export default UserProfiles;
