import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "./assets/flox_blue_x50.png";
import { NavLink, Link, Redirect } from "react-router-dom";
import { logout } from "./utils";

class LogoutNavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedOut: false,
    };
  }

  logout = () => {
    logout().then((res) => {
      this.props.logout();
      res.ok && this.setState({ loggedOut: true });
    });
  };

  render() {
    const { loggedOut } = this.state;
    const { user } = this.props;
    return (
      <div>
        <Navbar bg="light" variant="light">
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} alt="logo" />
            </Navbar.Brand>
            <div className="large-divider" />
            <Nav className="mr-auto align-items-center">
              <NavLink
                to="/profiles"
                className="link"
                activeClassName="link-active"
              >
                Profiles
              </NavLink>
              <div className="large-divider" />
              <NavLink
                to="/packages"
                className="link"
                activeClassName="link-active"
              >
                Packages
              </NavLink>
              <div className="large-divider" />
              <li>
                <a target="_blank" href="/docs/index.html" className="link">
                  Documentation
                </a>
              </li>
              <div className="large-divider" />
              <NavLink
                to="/install"
                className="link"
                activeClassName="link-active"
              >
                Downloads
              </NavLink>
              <div className="large-divider" />
              <li>
                <a
                  target="_blank"
                  href="https://discourse.floxdev.com"
                  className="link"
                >
                  Help Forum
                </a>
              </li>
            </Nav>
            <div className="d-flex justify-content-end align-items-center">
              <div className="bold">{user.username}</div>
              <div className="divider" />
              <Dropdown>
                <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic">
                  <img alt="avatar" className="avatar" src={user.AvatarURL} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link to="/settings">
                      <div className="dropdown">Settings</div>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={this.logout}>
                    <Link to="/login">
                      <div className="dropdown">Sign Out</div>
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Container>
        </Navbar>
        {loggedOut && <Redirect to="/login" />}
      </div>
    );
  }
}

export default LogoutNavigationBar;
