import React from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import UpgradesTable from "./UpgradesTable";
import ConfirmUpgradeTable from "./ConfirmUpgradeTable";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import NewPackagesTable from "./NewPackagesTable";
import SyncUpdateModal from "./SyncUpdateModal";
import Moment from "react-moment";
import NoUpgrade from "./NoUpgrade";
import GlobalStateContext from "../GlobalState";
import { upgradeProfile } from "../utils";

class UpgradeFromProfile extends React.Component {
  policies = [
    "release-prod",
    "release-qa",
    "release-experimental",
    "enterprise-qa",
    "enterprise-prod",
  ];
  owner = this.props.variant.proOwner;
  name = this.props.variant.proName;

  constructor(props) {
    super(props);
    this.state = {
      all: false,
      show: false,
      mode: "",
      possibleUpgrades: [],
      showSyncUpdate: false,
    };
  }

  static contextType = GlobalStateContext;

  confirmButton = (sync) => {
    return (
      <Button
        onClick={
          !sync
            ? this.upgrade
            : () => this.setState({ showSyncUpdate: true, show: false })
        }
        variant="success"
        size="sm"
      >
        Confirm
      </Button>
    );
  };

  modal = (sync) => {
    let footer = null;
    let confirmUpgrade = null;

    if (this.state.mode === "confirm") {
      footer = <Modal.Footer>{this.confirmButton(sync)}</Modal.Footer>;
    }

    if (this.state.mode === "pending") {
      footer = (
        <Modal.Footer>
          <Spinner animation="border" />
        </Modal.Footer>
      );
    }

    if (this.state.all) {
      confirmUpgrade = <ConfirmUpgradeTable upgrades={this.props.upgrades} />;
    } else {
      confirmUpgrade = (
        <ConfirmUpgradeTable upgrades={this.state.possibleUpgrades} />
      );
    }

    return (
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {this.getName()}-{this.props.variant.gen}-link
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {confirmUpgrade}
          {this.failureAlert()}
        </Modal.Body>
        {footer}
      </Modal>
    );
  };

  handleClose = () => {
    this.setState({ show: false, mode: "" });
    this.props.update();
  };

  failureAlert = () => {
    if (this.state.mode === "failure") {
      return (
        <Alert variant="danger">
          <p>Failed to upgrade profile</p>
        </Alert>
      );
    }
  };

  getName = () => {
    return this.props.variant.proOwner + "/" + this.props.variant.proName;
  };

  formatDate = (time) => {
    return <Moment format="Do MMM YYYY">{time}</Moment>;
  };

  formatTime = (time) => {
    return <Moment format="HH:mm">{time}</Moment>;
  };

  selectAll = () => {
    this.setState({ all: !this.state.all, possibleUpgrades: [] });
  };

  addUpdate = (e, upgrade) => {
    if (e.target.checked) {
      var arr = this.state.possibleUpgrades.concat([upgrade]);
      this.setState({ possibleUpgrades: arr });
    } else {
      arr = this.state.possibleUpgrades.filter(
        (val) => val.current.pkgID !== upgrade.current.pkgID
      );
      this.setState({ possibleUpgrades: arr });
    }
  };

  upgrade = () => {
    this.setState({ mode: "pending" });
    const packages = this.state.possibleUpgrades.map((up) => ({
      pkgID: up.current.pkgID,
    }));
    upgradeProfile(this.owner, this.name, packages).then((res) => {
      if (res.ok) {
        this.setState({ confirm: false, show: false, mode: "" });
        this.props.update();
      } else {
        this.setState({ mode: "failure" });
      }
    });
  };

  syncUpdate = (owner, name, selected) => {
    const packages = selected.map((up) => ({
      pkgID: up.current.pkgID,
    }));
    return new Promise((resolve) => {
      upgradeProfile(owner, name, packages, true).then((res) => {
        if (res.ok) {
          res
            .json()
            .then((res) => resolve({ gen: res.variants[0].variant.gen }));
        }
      });
    });
  };

  getUpgrades = () => {
    return (
      <div>
        Upgrades{" "}
        <Badge variant="success">
          {this.props.upgrades ? this.props.upgrades.length : 0}
        </Badge>
      </div>
    );
  };

  getNewPackages = () => {
    return (
      <div>
        Adding Packages{" "}
        <Badge variant="success">
          {this.props.new ? this.props.new.length : 0}
        </Badge>
      </div>
    );
  };

  getOldPackages = () => {
    return (
      <div>
        Removing Packages{" "}
        <Badge variant="danger">
          {this.props.old ? this.props.old.length : 0}
        </Badge>
      </div>
    );
  };

  getTimeFromProfileAlert = (variant) => {
    return (
      <Alert variant="primary">
        This profile will be upgraded from{" "}
        <Alert.Link
          href={
            "/profiles/" +
            variant.upgradeFrom.proOwner +
            "/" +
            variant.upgradeFrom.proName +
            "/status"
          }
        >
          {variant.upgradeFrom.proOwner}/{variant.upgradeFrom.proName}
        </Alert.Link>{" "}
        on <b>{this.formatDate(variant.nextUpgrade)}</b> at{" "}
        <b>{this.formatTime(variant.nextUpgrade)}</b>
      </Alert>
    );
  };

  getNormalAlert = (nextUpgrade) => {
    return (
      <Alert variant="primary">
        This profile will be upgraded on <b>{this.formatDate(nextUpgrade)}</b>{" "}
        at <b>{this.formatTime(nextUpgrade)}</b>
      </Alert>
    );
  };

  getReleaseFromProfileAlert = (variant) => {
    return (
      <Alert variant="primary">
        This profile will be upgraded from{" "}
        <Alert.Link
          href={
            "/profiles/" +
            variant.upgradeFrom.proOwner +
            "/" +
            variant.upgradeFrom.proName +
            "/status"
          }
        >
          {variant.upgradeFrom.proOwner}/{variant.upgradeFrom.proName}
        </Alert.Link>{" "}
        with the next <b>{variant.uppName}</b> release
      </Alert>
    );
  };

  getReleaseAlert = (variant) => {
    return (
      <Alert variant="primary">
        This profile will be upgraded during the next{" "}
        <b>{this.props.variant.uppName}</b> release or on{" "}
        <b>{this.formatDate(variant.nextUpgrade)}</b> at {""}
        <b>{this.formatTime(variant.nextUpgrade)}</b>, whichever happens sooner.
      </Alert>
    );
  };

  getAlert = (variant) => {
    // Does this profile have a release policy?
    if (this.policies.includes(variant.uppName)) {
      return variant.upgradeFrom
        ? this.getReleaseFromProfileAlert(variant)
        : this.getReleaseAlert(variant);
    }
    // Is this profile being upgraded from another?
    if (variant.upgradeFrom) {
      return this.getTimeFromProfileAlert(variant);
    }
    return this.getNormalAlert(variant.nextUpgrade);
  };

  getUpgradeButton = (upgradesSelected) => (
    <Button
      onClick={() => this.setState({ show: true, mode: "confirm" })}
      size="sm"
      variant="success"
      disabled={!upgradesSelected}
    >
      Upgrade
    </Button>
  );

  render() {
    const { update, variant, upToDate, old, upgrades, admin } = this.props;
    const { proOwner, proName } = variant;

    const { all, showSyncUpdate, possibleUpgrades } = this.state;
    const { config } = this.context;

    const enableSyncUpdates = variant.synchronous && config.wsURL !== "";

    const upgradesSelected = all ? true : possibleUpgrades.length > 0;

    return (
      <div>
        {variant && !upToDate && this.getAlert(variant)}
        {variant && !upToDate && <br />}
        {upToDate && <NoUpgrade />}
        {!upToDate && (
          <Tab.Container id="left-tabs-example" defaultActiveKey="upgrades">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="upgrades">{this.getUpgrades()}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="new">{this.getNewPackages()}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="old">{this.getOldPackages()}</Nav.Link>
              </Nav.Item>
            </Nav>
            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="upgrades">
                  {upgrades && (
                    <UpgradesTable
                      upgrades={upgrades}
                      variant={variant}
                      selectAll={this.selectAll}
                      addUpdate={this.addUpdate}
                      all={all}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="new">
                  {this.props.new && <NewPackagesTable old={this.props.new} />}
                </Tab.Pane>
                <Tab.Pane eventKey="old">
                  {old && <NewPackagesTable old={old} />}
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        )}
        {admin && !upToDate && <hr />}
        <div className="d-flex">
          {admin && !upToDate && this.getUpgradeButton(upgradesSelected)}
        </div>
        {this.modal(enableSyncUpdates)}
        <SyncUpdateModal
          show={showSyncUpdate}
          close={() => this.setState({ showSyncUpdate: false })}
          owner={proOwner}
          name={proName}
          update={update}
          upgrades={this.state.possibleUpgrades}
          action={this.syncUpdate}
        />
      </div>
    );
  }
}

export default UpgradeFromProfile;
