import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { requestToken, selectRange } from "../utils";

class AuthenticateModal extends React.Component {
  constructor(props) {
    super(props);
    this.command = React.createRef();
    this.state = {
      token: "",
      showTokenPage: false
    };
  }

  requestToken = authUser => {
    requestToken(authUser)
      .then(res => res.json())
      .then(res => this.setState({ token: res.token, showTokenPage: true }))
      .catch(err => console.log(err));
  };

  copyCommand = () => {
    const el = this.command.current;
    selectRange(el);
  };

  descriptionPage = owner => (
    <Modal.Body>
      <p>
        {"You'll need to prove you can authenticate as " +
          this.props.owner +
          " to modify this profile. By clicking next you'll be issued a token that will be need to be returned on the CLI as " +
          this.props.owner +
          "."}
      </p>
    </Modal.Body>
  );

  tokenPage = () => (
    <Modal.Body>
      <p>{"Run the following to validate this request: "}</p>
      <Alert variant="secondary">
        <div ref={this.command}>
          {"sudo -u " +
            this.props.owner +
            " floxpm auth verify " +
            this.state.token}
        </div>
      </Alert>
      <div className="d-flex justify-content-end click">
        <i className="material-icons" onClick={this.copyCommand}>
          file_copy
        </i>
      </div>
      <hr />
      <p>
        {"When you're finished, click done. You'll then temporarily have permissions to perform write operations on profiles owned by " +
          this.props.owner +
          "."}
      </p>
    </Modal.Body>
  );

  footer = () => (
    <Modal.Footer>
      <div className="d-flex">
        <Button size="sm" onClick={() => this.requestToken(this.props.owner)}>
          Next
        </Button>
      </div>
    </Modal.Footer>
  );

  confirmFooter = () => (
    <Modal.Footer>
      <div className="d-flex">
        <Button size="sm" onClick={this.props.update}>
          Done
        </Button>
      </div>
    </Modal.Footer>
  );

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.update}>
        <Modal.Header closeButton>
          <Modal.Title>
            {"Edit " + this.props.owner + "/" + this.props.name}
          </Modal.Title>
        </Modal.Header>
        {!this.state.showTokenPage && this.descriptionPage(this.props.owner)}
        {this.state.showTokenPage && this.tokenPage()}
        {!this.state.showTokenPage && this.footer()}
        {this.state.showTokenPage && this.confirmFooter()}
      </Modal>
    );
  }
}

export default AuthenticateModal;
