import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";

const spinner = (
  <Spinner animation="border" role="status">
    <span className="sr-only">Loading...</span>
  </Spinner>
);

class UpgradesTable extends React.Component {
  formatTime = (time) => {
    return (
      <Moment unix format="Do MMM YYYY HH:mm">
        {time}
      </Moment>
    );
  };

  getCheckbox = (upgrade) => {
    if (this.props.all) {
      return <Form.Check disabled checked aria-label="option-1" />;
    }
    return (
      <Form.Check
        aria-label="option-1"
        key={upgrade.current.drvID}
        onClick={(e) => this.props.addUpdate(e, upgrade)}
      />
    );
  };

  render() {
    if (this.props.upgrades.length === 0) {
      return spinner;
    }
    return (
      <Table size="sm" striped bordered>
        <thead>
          <tr>
            <th>
              <Form.Check
                aria-label="option-1"
                onClick={this.props.selectAll}
              />
            </th>
            <th>Package Name</th>
            <th>Current</th>
            <th>Build Time</th>
            <th>{this.props.variant.upgradeFrom ? "Target" : "Latest"}</th>
            <th>Build Time</th>
          </tr>
        </thead>
        <tbody>
          {this.props.upgrades.map((drv) => (
            <tr>
              <td>{this.getCheckbox(drv)}</td>
              <td>
                {drv.current.chnName}.{drv.current.pkgName}
              </td>
              <td>
                <Link
                  to={
                    "/derivation/" +
                    drv.current.drvID +
                    "/" +
                    drv.current.pkgVarID
                  }
                >
                  {drv.current.drvVersion}
                </Link>
              </td>
              <td>{this.formatTime(drv.current.drvTimestamp)}</td>
              <td>
                <Link
                  to={
                    "/derivation/" +
                    drv.latest.drvID +
                    "/" +
                    drv.latest.pkgVarID
                  }
                >
                  {drv.latest.drvVersion}
                </Link>
              </td>
              <td>{this.formatTime(drv.latest.drvTimestamp)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export default UpgradesTable;
