import React from 'react';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';

class AddPackagesButton extends React.Component {
  render() {
    return (
      <Link to="/packages/">
        <Button size="sm" variant="info">
          Add packages
        </Button>
      </Link>
    );
  }
}

export default AddPackagesButton;
