import React from "react";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import { requestToken, selectRange } from "../utils";

class AccessTokenModal extends React.Component {
  constructor(props) {
    super(props);
    this.command = React.createRef();
  }

  copyCommand = () => {
    const el = this.command.current;
    selectRange(el);
  };

  tokenPage = () => (
    <Modal.Body>
      <p>{"You've been issued an access token. Run the following to login using the Flox CLI: "}</p>
      <Alert variant="secondary">
        <div ref={this.command}>{"floxpm login " + this.props.token}</div>
      </Alert>
      <div className="d-flex justify-content-end click">
        <i className="material-icons" onClick={this.copyCommand}>
          file_copy
        </i>
      </div>
    </Modal.Body>
  );

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.close}>
        <Modal.Header closeButton>
          <Modal.Title>
            {"Access Token"}
          </Modal.Title>
        </Modal.Header>
        {this.tokenPage()}
      </Modal>
    );
  }
}

export default AccessTokenModal;
