import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { getSyncHostsProfile, getUpgradeWebSocket } from "../utils";
import Moment from "react-moment";
import GlobalStateContext from "../GlobalState";

class ShowHostsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hosts: [],
      search: "",
      loading: false,
      inProgress: false,
      finished: false,
      summary: null,
      ws: {},
      ackHosts: [],
      totalHosts: 0,
      completedHosts: [],
      failedHosts: [],
    };
  }

  static contextType = GlobalStateContext;

  getGeneration = () => {
    return (
      this.state.summary.profile.proOwner +
      "/" +
      this.state.summary.profile.proName +
      "/" +
      this.state.summary.profile.gen
    );
  };

  // Retrieve list of hosts that will perform update when the modal is shown
  // i.e. made visible to the user.
  componentDidUpdate = (prevProps) => {
    this.props.show == true &&
      this.props.show !== prevProps.show &&
      this.getHosts(this.props.owner, this.props.name);
  };

  doUpgrade = (owner, name) => () => {
    this.props.action(owner, name, this.props.upgrades).then((res) => {
      this.doSyncUpdate(owner, name, res.gen);
    });
  };

  doSyncUpdate = (owner, name, gen) => {
    var ws = getUpgradeWebSocket(this.context.config.wsURL, owner, name, gen);

    ws.onmessage = (e) => {
      var update = JSON.parse(e.data);
      update.ack &&
        this.setState({
          ackHosts: this.state.ackHosts.concat(update.ack),
        });
      update.confirm &&
        this.setState({
          completedHosts: this.state.completedHosts.concat(update.confirm),
        });
      update.failed &&
        this.setState({
          failedHosts: this.state.failedHosts.concat(update.failed),
        });
      update.total &&
        this.setState({
          totalHosts: update.total,
        });
      update.summary &&
        this.setState({
          summary: update.summary,
          finished: true,
          inProgress: false,
        });
    };

    this.setState({ inProgress: true, ws: ws });
  };

  resetState = () => {
    this.setState({
      inProgress: false,
      finished: false,
      ws: {},
      completedHosts: [],
      failedHosts: [],
      hosts: [],
      loading: false,
    });
  };

  stopUpgrade = () => {
    this.state.ws.send(JSON.stringify({ stop: true }));
  };

  backOut = () => {
    this.state.ws.send(JSON.stringify({ commit: false }));
    this.resetState();
    this.props.close();
    this.props.update();
  };

  flipProfile = () => {
    this.state.ws.send(JSON.stringify({ commit: true }));
    this.resetState();
    this.props.close();
    this.props.update();
  };

  closeModal = () => {
    this.state.ws.readyState && this.state.ws.close();
    this.resetState();
    this.props.close();
    this.props.update();
  };

  //Retrive the hosts that will download updates for this profile.
  getHosts = (owner, name) => {
    this.setState({ loading: true });
    getSyncHostsProfile(owner, name)
      .then((response) => response.json())
      .then((res) => {
        this.setState({ hosts: res.hosts, loading: false });
      })
      .catch((err) => console.error(err));
  };

  getDownloadHosts = () => {
    return this.state.ackHosts.filter(
      (host) => !this.state.completedHosts.includes(host)
    );
  };

  // Split column view of hosts which have/have not completed the download.
  getUpdateSummary = () => (
    <Row>
      <Col md={6}>
        {this.state.completedHosts.length != 0 && (
          <div>
            <b>Completed</b>
            <ListGroup>
              {this.state.completedHosts.sort().map((host) => (
                <ListGroup variant="flush">
                  <ListGroup.Item className="py-1">
                    <div className="d-flex justify-content-between">
                      <div>{host}</div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              ))}
            </ListGroup>
          </div>
        )}
        <br />
        {this.state.failedHosts.length != 0 && (
          <div>
            <b>Failed</b>
            <ListGroup>
              {this.state.failedHosts.sort().map((host) => (
                <ListGroup variant="flush">
                  <ListGroup.Item className="py-1">
                    <div className="d-flex justify-content-between">
                      <div>{host}</div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              ))}
            </ListGroup>
          </div>
        )}
      </Col>
      <Col md={6}>
        <b>Downloading</b>
        <ListGroup>
          {this.getDownloadHosts()
            .sort()
            .map((host) => (
              <ListGroup variant="flush">
                <ListGroup.Item className="py-1">
                  <div className="d-flex justify-content-between">
                    <div>{host}</div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            ))}
        </ListGroup>
      </Col>
    </Row>
  );

  updateSummary = () => {
    return (
      <div>
        <Row>
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <div>Completed hosts</div>
              </Col>
              <Col>
                <div>
                  {this.state.summary.complete
                    ? this.state.summary.complete
                    : 0}
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div>Failed hosts</div>
              </Col>
              <Col>
                <div>
                  {this.state.summary.failed ? this.state.summary.failed : 0}
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div>Completed</div>
              </Col>
              <Col>
                <div>{this.state.summary.comp ? "True" : "False"}</div>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <div>Start time</div>
              </Col>
              <Col>
                <Moment format="Do MMM YYYY HH:mm:ss">
                  {this.state.summary.start}
                </Moment>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div>Finish time</div>
              </Col>
              <Col>
                <Moment format="Do MMM YYYY HH:mm:ss">
                  {this.state.summary.finish}
                </Moment>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
      </div>
    );
  };

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  filterSearch = (host) => {
    return this.state.search === "" || host.includes(this.state.search);
  };

  render() {
    var numHosts = this.state.hosts ? this.state.hosts.length : 0;
    const { show, owner, name } = this.props;
    const {
      inProgress,
      finished,
      completedHosts,
      failedHosts,
      totalHosts,
      loading,
      hosts,
    } = this.state;

    return (
      <Modal size="lg" show={show} onHide={this.closeModal} scrollable>
        <Modal.Header closeButton>
          {!inProgress && !finished && (
            <Modal.Title>
              {numHosts} hosts will download this update
            </Modal.Title>
          )}
          {inProgress && (
            <Modal.Title>
              <div className="d-flex align-items-center">
                Update In Progress
                <div className="large-divider" />
                <div>
                  {completedHosts.length + failedHosts.length}/{totalHosts}
                </div>
                <div className="large-divider" />
                <Spinner animation="border" />
              </div>
            </Modal.Title>
          )}
          {!inProgress && finished && (
            <Modal.Title>
              {"Update Summary (" + this.getGeneration() + " )"}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {finished && this.updateSummary()}
          {!finished && (
            <div>
              <Row className="align-items-center">
                <Col sm={6}>
                  <Form.Control
                    id="search"
                    type="text"
                    placeholder="Search hosts ..."
                    autocomplete="off"
                    onChange={this.handleChange}
                  ></Form.Control>
                </Col>
                {!inProgress && !finished && (
                  <Col>
                    <div className="d-flex">
                      <i
                        className="material-icons click"
                        onClick={() => this.getHosts(owner, name)}
                      >
                        refresh
                      </i>
                      <div className="divider" />
                      <div>Refresh active hosts</div>
                    </div>
                  </Col>
                )}
              </Row>
              <br />
              {!loading &&
                !finished &&
                !inProgress &&
                hosts &&
                hosts
                  .filter(this.filterSearch)
                  .sort()
                  .map((host) => (
                    <ListGroup variant="flush">
                      <ListGroup.Item className="py-1">
                        <div>{host}</div>
                      </ListGroup.Item>
                    </ListGroup>
                  ))}
              {(finished || inProgress) && this.getUpdateSummary()}
              {loading && <Spinner animation="border" />}
            </div>
          )}
          {finished && (
            <div>
              <Row className="align-items-center">
                <Col sm={7}>
                  <b>Proceed with update</b>
                  <div>
                    Roll profile symlink forward globally to point at new
                    generation{" "}
                  </div>
                </Col>
                <Col className="justify-content-end">
                  <Button
                    className="justify-content-end"
                    size="sm"
                    variant="outline-success"
                    onClick={this.flipProfile}
                  >
                    Proceed
                  </Button>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm={7}>
                  <b>Back out of update</b>
                  <div>Keep profile at current generation</div>
                </Col>
                <Col className="justify-content-end">
                  <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={this.backOut}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!inProgress && !finished && (
            <Button
              disabled={loading}
              size="sm"
              variant="success"
              onClick={this.doUpgrade(owner, name)}
            >
              Proceed
            </Button>
          )}
          {inProgress && (
            <div className="d-flex justify-content-start">
              <div className="d-flex">
                <Button size="sm" variant="success" onClick={this.stopUpgrade}>
                  Stop
                </Button>
              </div>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ShowHostsModal;
