import React from 'react';
import { Link } from "react-router-dom";

export const hashLength = 33;

async function fetchRetrying({ resource, init, successIf }) {
  for (let i = 0; i < 3; i++) {
    let response = await fetch(resource, init);

    if (successIf(response)) {
      return response;
    } else {
      await new Promise(r => setTimeout(r, 2000));
    }
  }

  return await fetch(resource, init);
}

export async function getCVEbyCPEs(specs) {
  const cpes = specs.map(
    ({ vendor, product, version }) =>
      `cpe:/a:${vendor}:${product}:${version}`
  );

  cpes.sort();

  const uniqueCpes = [...new Set(cpes)];

  return await fetchRetrying({
    resource: "https://storehouse.beta.floxdev.com/sbom/cpes",
    init: {
      body: JSON.stringify({ name: uniqueCpes }),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      method: "POST",
      mode: "cors",
    },
    successIf: (response) => {
      return response.ok;
    },
  });
}

export async function getNixStorePathInfo(path) {
  const searchParams = new URLSearchParams({ path });

  return await fetchRetrying({
    resource: `https://storehouse.beta.floxdev.com/sbom/path-info?${searchParams}`,
    init: {
      headers: {
        "Accept": "application/json",
      },
      method: "GET",
      mode: "cors",
    },
    successIf: (response) => {
      return response.ok;
    },
  });
}

export function getPolicies() {
  var addr = "/api/policies";
  return fetch(addr);
}

export function getSystems() {
  var addr = "/api/systems";
  return fetch(addr);
}

export function getCookie() {
  var addr = "/api/cookie";
  return fetch(addr);
}

export function getChannels() {
  var addr = "/api/channels";
  return fetch(addr);
}

export function getUser() {
  var addr = "/api/user";
  return fetch(addr);
}

export function deleteProfile(owner, profile) {
  var addr = "/api/profiles/" + owner + "/" + profile;
  return fetch(addr, {
    method: "DELETE"
  });
}

export function deleteUser() {
  var addr = "/api/user";
  return fetch(addr, {
    method: "DELETE"
  });
}

export function getPackages(owner, profile) {
  var addr = "/api/profiles/" + owner + "/" + profile + "/packages";
  return fetch(addr);
}

export function getPackageVariants(chn, job, pkg) {
  var addr = "/api/packagevariants/" + chn + "/" + job + "/" + pkg;
  return fetch(addr);
}

export function getAllPackages() {
  var addr = "/api/packages";
  return fetch(addr);
}

export function getGenerations(owner, profile) {
  var addr = "/api/profiles/" + owner + "/" + profile + "/generations";
  return fetch(addr);
}

export function getDerivations(owner, profile, gen) {
  var addr = "/api/profiles/" + owner + "/" + profile + "/generations/" + gen;
  return fetch(addr);
}

export function getVariant(owner, profile) {
  var addr = "/api/profiles/" + owner + "/" + profile + "/variants";
  return fetch(addr);
}

export function getProfiles() {
  var addr = "/api/profiles";
  return fetch(addr);
}

export function getAuthProfiles() {
  var addr = "/api/authprofiles";
  return fetch(addr);
}

export function getProfileByID(proID) {
  var addr = "/api/profileid/" + proID;
  return fetch(addr);
}

export function getUserProfiles(owner) {
  var addr = "/api/profiles/" + owner;
  return fetch(addr);
}

export function getAllGenDrvs(owner, profile) {
  var addr = "/api/profiles/" + owner + "/" + profile + "/all";
  return fetch(addr);
}

export function rollback(owner, profile, gen) {
  var addr = "/api/profiles/" + owner + "/" + profile + "/instance";
  return fetch(addr, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ generation: Number(gen) })
  });
}

export function getPackageName(drv) {
  if (!drv.pkgName) {
    if (drv.nixStoreKey && drv.nixStoreKey.length > hashLength) {
      return drv.nixStoreKey.slice(hashLength, drv.nixStoreKey.length);
    }

    return "";
  }
  return drv.chnName + "." + drv.jobset + "." + drv.pkgName;
}

export function getPackageNameWithOutType(drv) {
  if (!drv.pkgName) {
    if (drv.nixStoreKey && drv.nixStoreKey.length > hashLength) {
      return drv.nixStoreKey.slice(hashLength, drv.nixStoreKey.length);
    } else {
      return "";
    }
  }
  return `${drv.chnName}.${drv.jobset}.${drv.pkgName} (${drv.outType})`;
}

export function parseNixStorePath(path) {
  const hash = path.slice(11, 43);
  const baseName = path.slice(44);
  const baseNameChunks = baseName.split('-');
  const name = baseNameChunks.length > 0 ? baseNameChunks[0] : "";
  const version = baseNameChunks.length > 1 ? baseNameChunks[1] : "";

  return {
    hash,
    name,
    version,
  };
}

export function getSimplePackageName(drv) {
  if (!drv.pkgName) {
    if (drv.nixStoreKey) {
      const parsed = parseNixStorePath(`/nix/store/${drv.nixStoreKey}`);
      return parsed.name;
    }

    return "";
  }
  return drv.pkgName;
}

export function removePackage(owner, profile, packages) {
  var addr = "/api/profiles/" + owner + "/" + profile + "/packages";
  return fetch(addr, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(packages)
  });
}

export function installPackage(owner, profile, packages) {
  var addr = "/api/profiles/" + owner + "/" + profile + "/packages";
  return fetch(addr, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(packages)
  });
}

export function installDerivation(owner, name, drvID, pkgVarID) {
  var addr = "/api/variants/" + owner + "/" + name + "/derivations";
  return fetch(addr, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ drvID: drvID, pkgVarID: pkgVarID })
  });
}

export function createProfile(owner, name, policy, system) {
  var addr = "/api/profiles/" + owner;
  return fetch(addr, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      owner: owner,
      name: name,
      policy: policy,
      grp: owner,
      systemName: system
    })
  });
}

export function getProfileVariantHistory(pvrID) {
  var addr = "/api/variants/" + pvrID + "/history";
  return fetch(addr);
}

export function upgradeProfile(owner, name, packages, sync = false) {
  var addr = "/api/profiles/" + owner + "/" + name + "/upgrade";
  return fetch(addr, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      packages: packages,
      sync: sync
    })
  });
}

export function getPackageDerivations(chn, job, pkg, systemID) {
  var addr = "/api/derivations/" + chn + "/" + job + "/" + pkg;
  if (systemID) addr += "?systemID=" + systemID;
  return fetch(addr);
}

export function getLatestDerivation(chn, job, pkg, systemID) {
  var addr = "/api/latest/" + chn + "/" + job + "/" + pkg;
  if (systemID) addr += "?systemID=" + systemID;
  return fetch(addr);
}

export function getDerivation(drvID, pkgVarID) {
  var addr = "/api/derivation/" + drvID + "?pkgVarID=" + pkgVarID;
  return fetch(addr);
}

export function getDerivationOutputs(drvID) {
  var addr = "/api/derivation/" + drvID + "/outputs";
  return fetch(addr);
}

export function getUpgrades(owner, profile) {
  var addr = "/api/profiles/" + owner + "/" + profile + "/upgrade";
  return fetch(addr);
}

export function editProfile(
  proID,
  owner,
  profile,
  policy,
  upgradeProfile,
  description,
  sync
) {
  var addr = "/api/profiles/" + owner + "/" + profile;
  return fetch(addr, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      id: proID,
      name: profile,
      upgradePolicy: policy,
      upgradeFrom: upgradeProfile,
      description: description,
      synchronous: sync
    })
  });
}

export function getActivity() {
  var addr = "/api/activity";
  return fetch(addr);
}

export function getPopular() {
  var addr = "/api/popular";
  return fetch(addr);
}

export function setDerivationPriority(pvrID, pkgVarID, priority) {
  var addr = "/api/variants/" + pvrID + "/derivations";
  return fetch(addr, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ pkgVarID: pkgVarID, priority: Number(priority) })
  });
}

export function getSubscriptions() {
  var addr = "/api/subscriptions";
  return fetch(addr);
}

export function subscribe(owner, name) {
  var addr = "/api/subscriptions";
  return fetch(addr, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ owner: owner, name: name })
  });
}

export function unsubscribe(owner, name) {
  var addr = "/api/subscriptions";
  return fetch(addr, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ owner: owner, name: name })
  });
}

export function delNixPath(owner, name, packages) {
  var addr = "/api/profiles/" + owner + "/" + name + "/derivations";
  return fetch(addr, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(packages)
  });
}

export function requestToken(authUser) {
  var addr = "/api/token/" + authUser;
  return fetch(addr);
}

export function isAuthorised(user, authusers, owner) {
  if (user === owner) {
    return true;
  }
  if (!authusers) {
    return false;
  }
  return authusers.map(user => user.username).includes(owner);
}

export function getSyncHostsProfile(owner, name) {
  var addr = "/api/sync/hosts/" + owner + "/" + name;
  return fetch(addr);
}

export function getUpgradeWebSocket(host, owner, name, gen) {
  var addr = host + "/api/sync/update/" + owner + "/" + name + "/" + gen;
  return new WebSocket(addr);
}

export function joinSyncUpdateWS(host, uuid) {
  var addr = host + "/api/syncupdate/" + uuid + "/join";
  return new WebSocket(addr);
}

export function getRequisites(owner, name, gen) {
  var addr = "/api/requisites/" + owner + "/" + name + "/" + gen;
  return fetch(addr);
}

export function getPackageHolds(owner, name) {
  var addr = "/api/" + "profiles/" + owner + "/" + name + "/" + "packageholds";
  return fetch(addr);
}

export function addPackageHold(owner, name, path) {
  var addr = "/api/profiles/" + owner + "/" + name + "/packageholds";
  return fetch(addr, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ nixStorePath: path })
  });
}

export function removePackageHold(owner, name, path) {
  var addr = "/api/profiles/" + owner + "/" + name + "/packageholds";
  return fetch(addr, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ nixStorePath: path })
  });
}

// Provides ability to copy text from an element to the document clipboard.
export function selectRange(el) {
  var range = document.createRange();
  range.selectNode(el);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);

  document.execCommand("copy");
}

export function getSyncUpdates() {
  var addr = "/api/syncupdates";
  return fetch(addr);
}

export function getSyncUpdate(uuid) {
  var addr = "/api/syncupdate/" + uuid;
  return fetch(addr);
}

export function login(username, password) {
  var addr = "/api/login";
  return fetch(addr, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ username: username, password: password })
  });
}

export function logout() {
  var addr = "/api/logout";
  return fetch(addr, {
    method: "POST"
  });
}

export function register(username, email, password) {
  var addr = "/api/register";
  return fetch(addr, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      username: username,
      emailAddr: email,
      password: password
    })
  });
}

export function reqAccessToken() {
  var addr = "/api/token";
  return fetch(addr, {
    method: "POST"
  });
}

export function getAccessTokens() {
  var addr = "/api/tokens";
  return fetch(addr);
}

export function revokeAccessToken(token) {
  var addr = "/api/token/" + token;
  return fetch(addr, {
    method: "DELETE"
  });
}

export function getConfig() {
  var addr = "/config";
  return fetch(addr);
}

export function getProfileHosts(owner, name) {
  var addr = "/api/profiles/" + owner + "/" + name + "/hosts";
  return fetch(addr);
}

//
// hydraURL is of the form https://storehouse.core.floxdev.com/build/.
// Replace "build" with "sbom" in this string to yield the sbom browser URL.
// XXX FIXME refactor
//
export function hydraUrlToSbomUrl(url) {
  var parts = url.split('/');
  parts.splice(3, 2, 'sbom/explore.html?path=');
  return parts.join('/');
};

export function getVersion(drv){
  if (!drv.drvVersion) {
    if (drv.nixStoreKey && drv.nixStoreKey.length > hashLength) {
      return drv.nixStoreKey.slice(0, 8);
    }

    return "";
  }

  return drv.drvVersion;
}

export function getVersionLink(drv) {
  if (!drv.drvVersion) {
    return getVersion(drv);
  }

  return (
    <Link to={"/derivation/" + drv.drvID + "/" + drv.pkgVarID}>
      {getVersion(drv)}
    </Link>
  );
};
