import React from 'react';
import Table from 'react-bootstrap/Table';
import SbomHref from "../common/SbomHref";
import GlobalStateContext from "../GlobalState";

class DerivationInfoTable extends React.Component {
  nixStorePath = '/nix/store/';

  static contextType = GlobalStateContext;

  getSbomHref = (path) => {
    return SbomHref(this.context.config.hydraURL, path);
  };

  isDefaultOutput = outType => {
    if (this.props.defaults.includes(outType)) {
      return <div className="green">&#10004;</div>;
    }
    return <div>&#10006;</div>;
  };

  render() {
    if (!this.props.info) {
      return null;
    }
    return (
      <Table size="sm">
        <thead>
          <tr>
            <th>Output Type</th>
            <th>Nix Path</th>
            <th>Default</th>
          </tr>
        </thead>
        <tbody>
          {this.props.outputs &&
            this.props.outputs.map(output => (
              <tr>
                <td>{output.outType ? output.outType : 'out'}</td>
                <td>{this.getSbomHref(this.nixStorePath + output.nixStoreKey)}</td>
                <td>
                  {this.isDefaultOutput(
                    output.outType ? output.outType : 'out',
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
  }
}

export default DerivationInfoTable;
