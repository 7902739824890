import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Footer from "../common/Footer";
import Alert from "react-bootstrap/Alert";
import GlobalStateContext from "../GlobalState";
import { selectRange } from "../utils";

const defaultFormat = "rpm";

class Downloads extends React.Component {
  constructor(props) {
    super(props);
    this.rpm = React.createRef();
    this.deb = React.createRef();
    this.tar = React.createRef();
    this.state = {
      authusers: [],
    };
  }

  static contextType = GlobalStateContext;

  copyRPM = () => {
    const el = this.rpm.current;
    selectRange(el);
  };

  copyDeb = () => {
    const el = this.deb.current;
    selectRange(el);
  };

  copyTar = () => {
    const el = this.tar.current;
    selectRange(el);
  };

  getDocsLink = () => {
    return (
      <div>
        <br />
        <p>
          Please see the{" "}
          <a
            href="https://beta.floxdev.com/docs/tour/tour-1/#install-flox"
            target="_blank"
          >
            docs
          </a>{" "}
          for further installation instructions.
        </p>
      </div>
    );
  };

  render() {
    const { authusers, popular } = this.state;
    const { user, info, cookie } = this.context;
    const username = user.username;

    return (
      <div>
        {this.props.navBar()}
        <br />
        <Container>
          <br />
          <Row>
            <Col>
              <Tabs
                defaultActiveKey={defaultFormat}
                id="uncontrolled-tab-example"
              >
                <Tab eventKey="rpm" title="RedHat-Based Distros">
                  <br />
                  <b>Download</b>
                  <br />
                  <br />
                  <p>
                    Download the Flox client{" "}
                    <a href="https://beta.floxdev.com/files/rpm/flox.rpm">
                      here.
                    </a>
                  </p>
                  <hr />
                  <p>
                    Download with curl:
                    <br />
                    <Alert variant="secondary" className="d-flex">
                      <div ref={this.rpm}>
                        curl -f -b auth-floxpm={cookie}{" "}
                        https://beta.floxdev.com/files/rpm/flox.rpm -o flox.rpm
                      </div>
                      <div className="d-flex justify-content-end click">
                        <i className="material-icons" onClick={this.copyRPM}>
                          file_copy
                        </i>
                      </div>
                    </Alert>
                  </p>
                  {this.getDocsLink()}
                </Tab>
                <Tab eventKey="deb" title="Debian-Based Distros">
                  <br />
                  <b>Download</b>
                  <br />
                  <br />
                  <p>
                    Download the Flox client{" "}
                    <a href="https://beta.floxdev.com/files/deb/flox.deb">
                      here.
                    </a>
                  </p>
                  <hr />
                  <p>
                    Download with curl:
                    <br />
                    <Alert variant="secondary" className="d-flex">
                      <div ref={this.deb}>
                        curl -f -b auth-floxpm={cookie}{" "}
                        https://beta.floxdev.com/files/deb/flox.deb -o flox.deb
                      </div>
                      <div className="d-flex justify-content-end click">
                        <i className="material-icons" onClick={this.copyDeb}>
                          file_copy
                        </i>
                      </div>
                    </Alert>
                  </p>
                  {this.getDocsLink()}
                </Tab>
                <Tab eventKey="tar" title="Other Linux Distros">
                  <br />
                  <b>Download</b>
                  <br />
                  <br />
                  <p>
                    Download the Flox client{" "}
                    <a href="https://beta.floxdev.com/files/flox.tar.xz">
                      here.
                    </a>
                  </p>
                  <hr />
                  <p>
                    Download with curl:
                    <br />
                    <Alert variant="secondary" className="d-flex">
                      <div ref={this.tar}>
                        curl -f -b auth-floxpm={cookie}{" "}
                        https://beta.floxdev.com/files/flox.tar.xz -o
                        flox.tar.xz
                      </div>
                      <div className="d-flex justify-content-end click">
                        <i className="material-icons" onClick={this.copyTar}>
                          file_copy
                        </i>
                      </div>
                    </Alert>
                  </p>
                  {this.getDocsLink()}
                </Tab>
                <Tab eventKey="nixos" title="NixOS">
                  <br />
                  <b>Download</b>
                  <br />
                  <br />
                  <p>
                    For NixOS installations, please visit{" "}
                    <a
                      href="https://beta.floxdev.com/docs/tour/tour-1/#install-flox"
                      target="_blank"
                    >
                      here.
                    </a>
                  </p>
                </Tab>
              </Tabs>
            </Col>
          </Row>
          <Footer user={username} authusers={authusers} info={info} />
        </Container>
      </div>
    );
  }
}

export default Downloads;
