import React from 'react';
import Card from "react-bootstrap/Card";

class NoUpdate extends React.Component {
    render() {
        return (
            <div class="d-flex flex-row justify-content-start">
                <Card bg="light">
                    <Card.Body>
                        <Card.Title>
                            You're all up to date
                        </Card.Title>
                        <Card.Text>
                            All packages installed to this profile are up to date
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default NoUpdate;
