import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";

class NewPackagesTable extends React.Component {
  formatTime = (time) => {
    return (
      <Moment unix format="Do MMM YYYY HH:mm">
        {time}
      </Moment>
    );
  };

  getName = (drv) => {
    if (!drv.latest.pkgName) {
      return drv.latest.nixStoreKey.slice(33, drv.latest.nixStoreKey.length);
    }
    return drv.latest.chnName + "." + drv.latest.pkgName;
  };

  getVersion = (drv) => {
    if (!drv.latest.drvVersion) {
      return drv.latest.nixStoreKey.slice(0, 8);
    }
    return (
      <Link to={"/derivation/" + drv.latest.drvID + "/" + drv.latest.pkgVarID}>
        {drv.latest.drvVersion}
      </Link>
    );
  };

  render() {
    return (
      <Table size="sm" striped>
        <thead>
          <tr>
            <th>Package Name</th>
            <th>Version</th>
            <th>Build Time</th>
          </tr>
        </thead>
        <tbody>
          {this.props.old &&
            this.props.old.map((drv) => (
              <tr>
                <td>{this.getName(drv)}</td>
                <td>{this.getVersion(drv)}</td>
                <td>
                  {drv.latest.drvTimestamp
                    ? this.formatTime(drv.latest.drvTimestamp)
                    : null}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
  }
}

export default NewPackagesTable;
