import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CreateProfileForm from "./CreateProfileForm";
import CreateProfileAlertFailure from "./CreateProfileAlertFailure";
import { Redirect } from "react-router-dom";
import { createProfile } from "../utils";
import GlobalStateContext from "../GlobalState";

class CreateProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "form",
      error: "",
    };
  }

  static contextType = GlobalStateContext;

  createProfile = (username) => (name, policy, system) => {
    createProfile(username, name, policy, system)
      .then((res) => {
        if (res.ok) {
          this.setState({ mode: "success" });
          this.context.updateProfiles();
        } else {
          res
            .json()
            .then((res) =>
              this.setState({ error: res.error, mode: "failure" })
            );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  renderSuccess = (mode) => {
    if (mode === "success") {
      return <Redirect to="/profiles" />;
    }
  };

  renderForm = (mode, username) => {
    if (mode === "form") {
      return (
        <CreateProfileForm
          policies={this.context.policies}
          systems={this.context.systems}
          onSubmitForm={this.createProfile(username)}
          user={username}
        />
      );
    }
  };

  render() {
    const { mode, error } = this.state;
    const username = this.context.user.username;
    return (
      <div>
        {this.props.navBar()}
        <br />
        <Container>
          <Row>
            <Col>
              <h4>Create a new profile</h4>
              <p>
                A profile allows you to manage sets of software. You'll need one
                before you can start adding packages.
              </p>
              <hr />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              {this.renderSuccess(mode)}
              {this.renderForm(mode, username)}
              <CreateProfileAlertFailure
                close={() => this.setState({ mode: "form" })}
                error={error}
                show={mode === "failure"}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default CreateProfile;
