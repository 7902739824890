import React, {Component} from 'react';
import Table from 'react-bootstrap/Table';

class UpgradePoliciesTable extends Component {
  render() {
    if (!this.props.policies) {
      return null;
    }
    return (
      <Table size="sm" striped bordered hovered>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {this.props.policies.map(policy => (
            <tr>
              <td>{policy.name}</td>
              <td>{policy.description}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export default UpgradePoliciesTable;
