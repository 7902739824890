import React from "react";
import { Link, Redirect } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import { List } from "react-virtualized";
import InstallModal from "../derivations/InstallModal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import Fuse from "fuse.js";
import { getLatestDerivation, installPackage } from "../utils";

class PackagesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      redirect: false,
      derivation: 0,
      package: "",
      filteredPackages: [],
      inProgress: false,
      search: "",
    };
    this.timeout = 0;
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value, inProgress: true });
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(this.updatePackages, 200);
  };

  updatePackages = () => {
    var fuse = new Fuse(this.props.packages, {
      keys: [
        { name: "name", weight: 1 },
        { name: "description", weight: 1 },
      ],
      threshold: 0.4,
    });

    this.setState({
      filteredPackages: fuse.search(this.state.search),
      inProgress: false,
    });
  };

  getLatestDerivation = (proj, job, pkg) => {
    getLatestDerivation(proj, job, pkg)
      .then((res) => res.json())
      .then((res) =>
        this.setState({
          redirect: true,
          derivation: res.drvID,
          pkgVarID: res.pkgVarID,
        })
      )
      .catch((err) => console.log(err));
  };

  installPackage = (prof, pkg) =>
    installPackage(prof.proOwner, prof.proName, {
      packages: [
        {
          channel: { name: this.state.channel, jobset: this.state.jobset },
          packageName: this.state.package,
        },
      ],
    });

  rowRenderer = (packages) => ({ index, key, style }) => {
    var item = packages[index];
    var pkg = item.item ? item.item : item;

    return (
      <Row key={key} style={style} className="list-item align-items-center">
        <Col md={3}>
          <div className="chn-name">
            {pkg.channel.name}.{pkg.channel.jobset}
          </div>
        </Col>
        <Col md={7}>
          <div
            onClick={() =>
              this.getLatestDerivation(
                pkg.channel.name,
                pkg.channel.jobset,
                pkg.name
              )
            }
            className="pkg-name"
          >
            {pkg.name}
          </div>
          <small>{pkg.description}</small>
        </Col>
        <Col md={2}>
          <Button
            size="sm"
            variant="outline-success"
            onClick={this.clickInstall(
              pkg.name,
              pkg.channel.name,
              pkg.channel.jobset
            )}
          >
            Install
          </Button>
        </Col>
      </Row>
    );
  };

  clickInstall = (pack, chan, job) => () => {
    this.setState({ show: true, package: pack, channel: chan, jobset: job });
  };

  render() {
    if (!this.props.packages) {
      return null;
    }

    const { search, filteredPackages, inProgress } = this.state;

    var packagesLength =
      search !== "" ? filteredPackages.length : this.props.packages.length;

    var packages = search !== "" ? filteredPackages : this.props.packages;

    return (
      <Container>
        <Row className="align-items-center">
          <Col md={3}>
            <input
              type="text"
              className="streamlined"
              id="search"
              onChange={this.handleChange}
              value={search}
              placeholder={"Search " + packagesLength + " packages"}
            />
          </Col>
          {search !== "" &&
            (inProgress ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <Col>{filteredPackages.length + " results"}</Col>
            ))}
        </Row>
        <br />
        <List
          className="List"
          width={800}
          height={800}
          rowRenderer={this.rowRenderer(packages)}
          rowCount={packagesLength}
          rowHeight={80}
        />
        <InstallModal
          show={this.state.show}
          close={() => this.setState({ show: false, error: "" })}
          channel={this.state.channel}
          package={this.state.package}
          drv={{
            package: this.state.package,
            channel: { name: this.state.channel, jobset: this.state.jobset },
          }}
          jobset={this.state.jobset}
          profiles={this.props.profiles}
          user={this.props.user}
          install={this.installPackage}
          error={this.state.error}
        />
        {this.state.redirect && (
          <Redirect
            to={
              "/derivation/" + this.state.derivation + "/" + this.state.pkgVarID
            }
          />
        )}
      </Container>
    );
  }
}

export default PackagesList;
