import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Moment from "react-moment";

class SyncUpdateSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };
  }

  getGeneration = (owner, name, gen) => {
    return owner + "/" + name + "/" + gen;
  };

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  updateSummary = (summary) => (
    <Container>
      <Row>
        <Col sm={6}>
          <Row>
            <Col sm={6}>
              <div>Completed hosts</div>
            </Col>
            <Col>
              <div>{summary.complete ? summary.complete : 0}</div>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <div>Failed hosts</div>
            </Col>
            <Col>
              <div>{summary.failed ? summary.failed : 0}</div>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <div>Timed out</div>
            </Col>
            <Col>
              <div>
                {summary.ack -
                  ((summary.complete ? summary.complete : 0) +
                    (summary.failed ? summary.failed : 0))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <div>Completed</div>
            </Col>
            <Col>
              <div>{summary.comp ? "True" : "False"}</div>
            </Col>
          </Row>
        </Col>
        <Col sm={6}>
          <Row>
            <Col sm={6}>
              <div>Start time</div>
            </Col>
            <Col>
              <Moment format="Do MMM YYYY HH:mm:ss">{summary.start}</Moment>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <div>Finish time</div>
            </Col>
            <Col>
              <Moment format="Do MMM YYYY HH:mm:ss">{summary.finish}</Moment>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col sm={6}>
          <Form.Control
            id="search"
            type="text"
            placeholder="Search hosts ..."
            autocomplete="off"
            onChange={this.handleChange}
          ></Form.Control>
        </Col>
      </Row>
      <br />
      <Row>
        {summary.completedHosts && (
          <Col md={6}>
            <b>Completed</b>
            <ListGroup>
              {summary.completedHosts
                .filter(this.filterSearch)
                .sort()
                .map((host) => (
                  <ListGroup variant="flush">
                    <ListGroup.Item className="py-1">
                      <div className="d-flex justify-content-between">
                        <div>{host}</div>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                ))}
            </ListGroup>
          </Col>
        )}
        {summary.failedHosts && (
          <Col md={6}>
            <b>Failed</b>
            <ListGroup>
              {summary.failedHosts
                .filter(this.filterSearch)
                .sort()
                .map((host) => (
                  <ListGroup variant="flush">
                    <ListGroup.Item className="py-1">
                      <div className="d-flex justify-content-between">
                        <div>{host}</div>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                ))}
            </ListGroup>
          </Col>
        )}
      </Row>
      <hr />{" "}
    </Container>
  );

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  filterSearch = (host) => {
    return this.state.search === "" || host.includes(this.state.search);
  };

  render() {
    const { prof, summary, show, close } = this.props;

    return (
      <Modal size="lg" show={show} onHide={close} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>
            {"Update Summary (" +
              this.getGeneration(prof.proOwner, prof.proName, prof.gen) +
              " )"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{summary && this.updateSummary(summary)}</Modal.Body>
      </Modal>
    );
  }
}

export default SyncUpdateSummary;
