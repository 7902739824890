import React from 'react';
import Button from 'react-bootstrap/Button';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class ProfileGenerationsButton extends React.Component {
  render() {
    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>View Generations</Tooltip>}>
        <Button
          disabled={!this.props.disabled}
          href={
            '/profiles/' +
            this.props.owner +
            '/' +
            this.props.name +
            '/generations'
          }
          size="sm"
          variant="outline-dangers">
          <i className="material-icons">history</i>
        </Button>
      </OverlayTrigger>
    );
  }
}

export default ProfileGenerationsButton;
