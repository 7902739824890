import React from 'react';

function SbomSearchHref(url, key, label) {
  var parts = url.split('/');
  parts.splice(3, 2, 'sbom/search.html?search=' + key);
  var hrefUrl = parts.join('/');
  return (<a className="link-text" href={hrefUrl}>{label}</a>);
}

export default SbomSearchHref;
