import React from "react";
import UpgradeFromProfile from "./UpgradeFromProfile";

class Upgrades extends React.Component {
  owner = this.props.owner;
  name = this.props.name;

  constructor(props) {
    super(props);
    this.state = {
      upgrades: [],
      old: [],
      new: [],
      all: false,
      upToDate: false,
    };
  }

  componentDidMount = () => {
    this.props.getUpgrades();
  };

  update = () => {
    this.props.getVariant();
    this.props.getUpgrades();
  };

  render() {
    const {
      variant,
      admin,
      upgrades,
      oldPackages,
      newPackages,
      upToDate,
    } = this.props;
    if (!variant) {
      return null;
    }

    return (
      <div>
        <UpgradeFromProfile
          upgrades={upgrades}
          old={oldPackages}
          new={newPackages}
          variant={variant}
          update={this.update}
          upToDate={upToDate}
          admin={admin}
        />
      </div>
    );
  }
}

export default Upgrades;
