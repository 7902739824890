import React from 'react';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';

class ProfileCreateButton extends React.Component {
  render() {
    return (
      <Link to="/create/profile">
        <Button className="button-text" size="sm" variant="info">
          Create profile
        </Button>
      </Link>
    );
  }
}
export default ProfileCreateButton;
