import React from "react";
import { Link, Redirect } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import { installPackage, getLatestDerivation } from "../utils";

class PopularPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: {},
      show: false,
      showMore: false,
      redirect: false,
    };
  }

  getLatestDerivation = (proj, job, pkg) => {
    getLatestDerivation(proj, job, pkg)
      .then((res) => res.json())
      .then((res) =>
        this.setState({
          redirect: true,
          derivation: res.drvID,
          pkgVarID: res.pkgVarID,
        })
      )
      .catch((err) => console.log(err));
  };

  render() {
    if (!this.props.popular) {
      return null;
    }
    var count = 0;
    var max = this.props.popular.length > 10 ? 10 : this.props.popular.length;

    var show =
      this.props.popular.length >= 5
        ? this.state.showMore
          ? max
          : 5
        : this.props.popular.length;

    return (
      <div>
        <ListGroup>
          {this.props.popular.slice(0, show).map((entry) => {
            count++;
            return (
              <ListGroup.Item className="popular-packages">
                <div className="d-flex flex-column">
                  <div className="d-flex small">
                    <div>{count}</div>
                    <div className="small-divider" />
                    <div>&bull;</div>
                    <div className="small-divider" />
                    <div>
                      {entry.pkg.channel.name}.{entry.pkg.channel.jobset}
                    </div>
                    <div className="small-divider" />
                    <div>&bull;</div>
                    <div className="small-divider" />
                    <div>Installed to {entry.installed} profiles</div>
                  </div>
                  <Link
                    className="heading"
                    onClick={() =>
                      this.getLatestDerivation(
                        entry.pkg.channel.name,
                        entry.pkg.channel.jobset,
                        entry.pkg.name
                      )
                    }
                  >
                    {entry.pkg.name}
                  </Link>
                </div>
                <div className="d-flex align-items-center">
                  <div>{entry.pkg.description}</div>
                </div>
              </ListGroup.Item>
            );
          })}
          {this.props.popular.length > 5 && (
            <ListGroup.Item
              onClick={() => this.setState({ showMore: !this.state.showMore })}
              className="popular-packages"
            >
              <div className="link-text">
                {this.state.showMore ? "Show Less" : "Show More"}
              </div>
            </ListGroup.Item>
          )}
        </ListGroup>
        {this.state.redirect && (
          <Redirect
            to={
              "/derivation/" + this.state.derivation + "/" + this.state.pkgVarID
            }
          />
        )}
      </div>
    );
  }
}

export default PopularPackages;
