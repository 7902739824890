import React from 'react';
import Alert from 'react-bootstrap/Alert';

class DeleteProfileFailure extends React.Component {
  render() {
    return (
      <Alert variant="danger" show={this.props.show}>
        <Alert.Heading>Failed to delete profile</Alert.Heading>
        <hr />
        <b>{this.props.error}</b>
      </Alert>
    );
  }
}

export default DeleteProfileFailure;
