import React from 'react';
import Button from "react-bootstrap/Button";

class AuthenticateButton extends React.Component {
    render() {
        return(
            <Button size="sm" onClick={this.props.onClick} variant="info">Edit Profile</Button>
        );
    }
}

export default AuthenticateButton;
