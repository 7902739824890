import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import NoProfilesModal from "./NoProfilesModal";

class InstallDerivationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      error: "",
      showConfirm: false,
      profile: {}
    };
  }

  failureAlert = () => {
    return (
      <Alert variant="danger" show={this.state.error !== ""}>
        <b>Failed to install: {this.state.error}</b>
      </Alert>
    );
  };

  resetState = () => {
    this.setState({ profile: {}, show: true, showConfirm: false, error: "" });
  };

  installModal = () => {
    return (
      <Modal
        show={this.props.show && this.state.show}
        onHide={() => {
          this.resetState();
          this.props.close();
        }}
      >
        <Modal.Header className="modal-background">
          <Modal.Title>
            <div>Select a profile</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="d-flex justify-content-center">
              <ListGroup variant="flush">
                {this.props.profiles &&
                  this.props.profiles.map(prof => {
                    return (
                      <ListGroup.Item>
                        <Row>
                          <Col md={9}>
                            <div>{prof.proOwner + "/" + prof.proName}</div>
                          </Col>
                          <Col md={3}>
                            <Button
                              onClick={() =>
                                this.setState({
                                  profile: prof,
                                  show: false,
                                  showConfirm: true
                                })
                              }
                              size="sm"
                              variant="outline-success"
                            >
                              Select
                            </Button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
              {!this.props.profiles && (
                <Row>
                  <Col>
                    <div>
                      Looks like you don't have any profiles to install packages
                      to.
                    </div>
                    <div className="vertical-divider" />
                    <Link to="/create/profile">
                      <Button size="sm" variant="outline-success">
                        Create Profile
                      </Button>
                    </Link>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  confirmBody = () => {
    if (this.state.error === "") {
      return (
        <div>
          Install{" "}
          <b>
            {this.props.channel}.{this.props.package}
          </b>{" "}
          to{" "}
          <b>
            {this.state.profile.proOwner}/{this.state.profile.proName}?
          </b>
        </div>
      );
    }
  };

  confirmFooter = () => {
    if (this.state.error === "") {
      return (
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-success"
            onClick={() => this.setState({ showConfirm: false, show: true })}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              this.props
                .install(this.state.profile, this.props.drv)
                .then(res => {
                  if (res.ok) {
                    this.resetState();
                    this.props.close();
                  } else {
                    res.json().then(res => this.setState({ error: res.error }));
                  }
                });
            }}
            size="sm"
            variant="outline-success"
          >
            Install
          </Button>
        </Modal.Footer>
      );
    }
  };

  confirmModal = () => {
    return (
      <Modal
        show={this.props.show && this.state.showConfirm}
        onHide={() => {
          this.resetState();
          this.props.close();
        }}
      >
        <Modal.Header className="modal-background" closeButton>
          <Modal.Title>
            {this.props.channel}.{this.props.package}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.confirmBody()}
          {this.failureAlert()}
        </Modal.Body>
        {this.confirmFooter()}
      </Modal>
    );
  };

  render() {
    const noProfiles = !this.props.profiles || this.props.profiles.length === 0;

    if (noProfiles) {
      return <NoProfilesModal />;
    }

    const { profile } = this.state;
    return (
      <div>
        {this.installModal()}
        {this.confirmModal()}
      </div>
    );
  }
}

export default InstallDerivationModal;
