import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class SyncUpdatesList extends React.Component {
  render() {
    const { updates } = this.props;
    return (
      <ListGroup variant="flush">
        <div className="d-flex align-items-center">
          <Col md={8}>
            <div>Profile</div>
          </Col>
          <Col md={4}>
            <div>Status</div>
          </Col>
        </div>
        {updates.slice(0, 5).map((update) => (
          <ListGroup.Item
            key={
              update.profile.proOwner +
              "." +
              update.profile.proName +
              "." +
              update.start
            }
          >
            <Row className="align-items-center">
              <Col md={2}>
                <div className="d-flex justify-content-end">
                  <div className="gen-header">{update.profile.gen}</div>
                </div>
              </Col>
              <Col md={6}>
                <div className="d-flex align-items-center">
                  <div className="divider" />
                  <div className="d-flex flex-column">
                    <div
                      className="link-text"
                      onClick={() => this.props.select(update.UUID, update.profile)}
                    >
                      {update.profile.proOwner + "/" + update.profile.proName}
                    </div>
                    {update.comp && (
                      <Moment className="small" format="Do MMM YYYY HH:mm">
                        {update.finish}
                      </Moment>
                    )}
                    {!update.comp && <small>download in progress</small>}
                  </div>
                </div>
              </Col>
              <Col>
                {update.cancelled && (
                  <div className="d-flex justify-content-center">
                    <Row>
                      <div>update cancelled</div>
                    </Row>
                  </div>
                )}
                {!update.cancelled && (
                  <Row>
                    <Col>
                      <OverlayTrigger
                        trigger="hover"
                        placement="top"
                        overlay={<Tooltip>Completed downloads</Tooltip>}
                      >
                        <div className="d-flex justify-content-end">
                          <div className="green justify-content-end">
                            {update.complete ? update.complete : 0}
                          </div>
                        </div>
                      </OverlayTrigger>
                    </Col>
                    <Col>
                      <OverlayTrigger
                        trigger="hover"
                        placement="top"
                        overlay={<Tooltip>Failed downloads</Tooltip>}
                      >
                        <div className="d-flex justify-content-end">
                          <div className="danger">
                            {update.comp
                              ? update.failed
                                ? update.failed
                                : 0
                              : 0}
                          </div>
                        </div>
                      </OverlayTrigger>
                    </Col>
                    <Col>
                      <div className="d-flex justify-content-end">
                        {update.comp && <i className="material-icons">done</i>}
                        {update.cancelled && !update.comp && (
                          <i className="material-icons">done</i>
                        )}
                        {!update.comp && !update.cancelled && (
                          <Spinner size="sm" animation="border" />
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  }
}

export default SyncUpdatesList;
