import React from "react";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getSyncHostsProfile } from "../utils";

class SyncInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hosts: [],
      showModal: false,
      loading: false,
      search: "",
    };
  }

  filterSearch = (host) => {
    return this.state.search === "" || host.includes(this.state.search);
  };

  getHosts = (owner, name) => {
    this.setState({ loading: true });
    getSyncHostsProfile(owner, name)
      .then((response) => response.json())
      .then((res) => {
        this.setState({ hosts: res.hosts, loading: false });
      })
      .catch((err) => console.error(err));
  };

  showModal = (owner, name) => {
    this.getHosts(owner, name);
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  hostModal = (hosts, show, loading, owner, name) => (
    <Modal size="lg" show={show} onHide={this.closeModal} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          {hosts ? hosts.length : 0} hosts will download updates for this
          profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center">
          <Col sm={6}>
            <Form.Control
              id="search"
              type="text"
              placeholder="Search hosts ..."
              autocomplete="off"
              onChange={this.handleChange}
            ></Form.Control>
          </Col>
          {!loading && (
            <Col>
              <div className="d-flex">
                <i
                  className="material-icons click"
                  onClick={() => this.getHosts(owner, name)}
                >
                  refresh
                </i>
                <div className="divider" />
                <div>Refresh active hosts</div>
              </div>
            </Col>
          )}
        </Row>
        <br />
        <ListGroup variant="flush">
          {!loading &&
            hosts &&
            hosts
              .filter(this.filterSearch)
              .sort()
              .map((host) => (
                <ListGroup.Item className="py-1" key={host}>
                  <div>{host}</div>
                </ListGroup.Item>
              ))}
        </ListGroup>
        {loading && <Spinner animation="border" />}
      </Modal.Body>
    </Modal>
  );

  render() {
    const { hosts, showModal, loading } = this.state;
    const { owner, name } = this.props;
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div>Synchronous updates are enabled</div>
          <i className="material-icons">info</i>
        </div>
        <div
          onClick={() => this.showModal(owner, name)}
          className="link-text small"
        >
          View download hosts
        </div>
        {this.hostModal(hosts, showModal, loading, owner, name)}
      </div>
    );
  }
}

export default SyncInfo;
