import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ActivityListGroup from './ActivityListGroup';

class Activity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: {},
      show: false,
    };
  }

  getComment = comment => {
    if (comment.length > 200) {
      return <div className="comment">{comment.substring(0, 200)}</div>;
    }
    return <div className="comment">{comment}</div>;
  };

  render() {
    if (!this.props.activity) {
      return null;
    }
    return (
      <Tabs defaultActiveKey="myprofiles">
        <Tab eventKey="myprofiles" title="My Profiles">
          <ActivityListGroup
            activity={this.props.activity.filter(
              entry =>
                this.props.subscriptions.includes(
                  entry.proOwner + '/' + entry.proName,
                ) || entry.proOwner === this.props.user,
            )}
          />
        </Tab>
        <Tab eventKey="all" title="All">
          <ActivityListGroup activity={this.props.activity} />
        </Tab>
      </Tabs>
    );
  }
}

export default Activity;
